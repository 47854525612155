/* Provide sufficient contrast against white background */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://use.fontawesome.com/releases/v5.6.3/css/all.css');

.statusDP0 > div > a {
    color: #ffffff !important;
}

.statusDP0 > div > div > svg {
    color: #fff !important;
    fill: #fff !important;
}


.statusDP0 > div > div > div > svg {
    color: #fff !important;
    fill: #fff !important;
}



.statusDP1 > div > a {
    color: #ffffff !important;
}

.statusDP1 > div > div > svg {
    color: #fff !important;
    fill: #fff !important;
}

.statusDP1 > div > div > div > svg {
    color: #fff !important;
    fill: #fff !important;
}

.statusDP1 > div > label > a {
    color: #ffffff !important;
}



.statusDP1 > div > a {
    color: #ffffff !important;
}

.statusDP1 > div > label {
    color: #ffffff !important;
}


.statusDP2 > div > a {
    color: #ffffff !important;
}

.statusDP2 > div > label {
    color: #ffffff !important;
}



.statusDP2 > div > div > div > svg {
    color: #fff !important;
    fill: #fff !important;
}

.statusDP2 > div > a {
    color: #ffffff !important;
}

.statusDP2 > div > div > svg {
    color: #fff !important;
    fill: #fff !important;
}


.statusDP2 > div > label > a {
    color: #ffffff !important;
}

.statusDP6 > div {
    color: #e10412 !important;
}

    .statusDP6 > div > a {
        color: #e10412 !important;
    }

    .statusDP6 > div > div > svg {
        color: #e10412 !important;
    }

    .statusDP6 > div > div > div > svg {
        color: #e10412 !important;
    }

    .statusDP6 > div > label {
        color: #e10412 !important;
    }

        .statusDP6 > div > label > a {
            color: #e10412 !important;
        }

.statusDP4 > div {
    color: #f2c94c !important;
}

    .statusDP4 > div > a {
        color: #f2c94c !important;
    }

    .statusDP4 > div > div > svg {
        color: #f2c94c !important;
    }

    .statusDP4 > div > div > div > svg {
        color: #f2c94c !important;
    }

    .statusDP4 > div > label {
        color: #f2c94c !important;
    }

        .statusDP4 > div > label > a {
            color: #f2c94c !important;
        }


.statusDP3 > div {
    color: #2f80ed !important;
}

    .statusDP3 > div > a {
        color: #2f80ed !important;
    }

    .statusDP3 > div > div > svg {
        color: #2f80ed !important;
    }

    .statusDP3 > div > div > div > svg {
        color: #2f80ed !important;
    }

    .statusDP3 > div > label {
        color: #2f80ed !important;
    }

        .statusDP3 > div > label > a {
            color: #2f80ed !important;
        }


.statusDP5 > div {
    color: #0dba61 !important;
}

    .statusDP5 > div > a {
        color: #0dba61 !important;
    }

    .statusDP5 > div > div > svg {
        color: #0dba61 !important;
    }

    .statusDP5 > div > div > div > svg {
        color: #0dba61 !important;
    }

    .statusDP5 > div > label {
        color: #0dba61 !important;
    }

        .statusDP5 > div > label > a {
            color: #0dba61 !important;
        }


.statusDPBlockOut > div {
    color: #a9a9a9 !important;
}

    .statusDPBlockOut > div > a {
        color: #a9a9a9 !important;
    }

    .statusDPBlockOut > div > div > svg {
        color: #a9a9a9 !important;
    }

    .statusDPBlockOut > div > div > div > svg {
        color: #a9a9a9 !important;
    }

    .statusDPBlockOut > div > label {
        color: #a9a9a9 !important;
    }

        .statusDPBlockOut > div > label > a {
            color: #a9a9a9 !important;
        }

.mxButton {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.afterspace:empty::after {
    content: ".";
    visibility: hidden;
}

.afterspace {
    height: 20px;
    width: 20px;
    background-color: white;
}

.iconButton {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    cursor: pointer;
}

.confirmBox {
    max-width: 400px !important;
}

.uploadText {
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: center;
    color: #121212;
    padding-bottom: 20px;
}

.flCol {
    flex-direction: column !important;
    border-radius: 8px;
    border: solid 1px #d6d8e7;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}

.fullFooterButton {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: #f47d30;
    padding: 13.5px 0px 18px 0px;
    border: solid 0.25px rgba(244, 125, 48, 0.75);
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #fff !important;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #fff !important;
}

.form-border {
    border: solid 1px #d6d8e7 !important;
    width: 228px;
    height: 38px;
}

    .form-border:focus {
        border-color: #f47d30 !important;
        box-shadow: 0 0 0 0.2rem rgba(244, 125, 48,0.25) !important;
        outline: none;
    }

.custom-file-input ~ .custom-file-label::after {
    font-family: 'Font Awesome 5 Free';
    content: "\f382" !important;
    font-weight: 900;
    color: orange;
    border: none;
    background-color: transparent;
    font-size: 30px;
    margin-top: -11px;
}

.custom-file-input ~ .custom-file-label-camera::after {
    font-family: 'Font Awesome 5 Free';
    content: "\f030" !important;
    font-weight: 900;
    background-color: white;
    color: orange;
    border: none;
    font-size: 2em;    
}


.zIndex3 {
    z-index: 3 !important;
}


.Select-menu-outer {
    z-index: 1000;
    position: relative;
}

.custom-file-label {
    padding-top: -10px;
    position: relative !important;
    width: 100%;
    border: none;
    padding-left: 0px;
}

.custom-file-label-camera {
    padding-top: -10px;
    position: relative !important;
    width: 93%;
    border: none;
    padding-left: 0px;
    border: none !important;
    margin-right: 5px !important;
}

body {
    margin: 0;
    height: 100vh;
    color: #353535;
    font-family: 'Roboto', sans-serif;
}

#root {
    height: 100%;
}

.app {
    height: 100%;
    display: flex;
    position: relative;
    background-color: #25313e !important;
}

svg {
    width: 1.5em;
    height: 1.5em;
}


.calendar-svg {
    width: 1.2em !important;
    height: 1.2em !important;
}

.app main footer {
    margin-top: auto;
    color: #888;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: 1px;
    color: #f47d30;
}

.mainWAuth {
    margin: 24px 24px 24px 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: #fff !important;
    border: 24px solid white;
    border-radius: 24px;
}

.mainWAuthPln {
    margin: 24px 24px 24px 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    background-color: #fff !important;
    border: 24px solid white;
    border-radius: 24px;
}

.mainWOAuth {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: #fff !important;
}

.mainDarkModeTheme {
    margin: 24px 24px 24px 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: #222c31 !important;
    border: 24px solid #222c31;
    border-radius: 24px;
}


.bordermain {
    border: 24px solid #25313e;
    box-shadow: 0 0 10px black;
}

.pro-sidebar-layout {
    background-color: #25313e !important;
    padding-top: 24px;
    padding-left: 30px;
}

.pro-icon-wrapper {
    background-color: transparent !important;
}

.pro-sidebar.collapsed {
    width: 100px !important;
    min-width: 100px !important;
}

.pro-sidebar-header {
    border: none !important;
}

.pro-sidebar {
    width: 320px !important;
}

.iconBg {
    background-color: #25313e;
}


.activeMenu {
    background-color: white;
    color: #f47d30;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 1px;
    text-align: left;
}

.activeMenuDarkModeTheme {
    background-color: #222c31 !important;
    color: #f47d30;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 1px;
    text-align: left;
}


.activeTop {
    background-color: #fff;
}

.activeTopDarkModeTheme {
    background-color: #222c31 !important;
}

.activeTopInner {
    border-bottom-right-radius: 30px;
    background-color: #25313e;
    border-bottom-color: white;
}

.activeTopInnerDarkModeTheme {
    border-bottom-right-radius: 30px;
    background-color: #25313e;
    border-bottom-color: black;
}

.activeBottom {
    background-color: #fff;
}

.activeBottomDarkModeTheme {
    background-color: #222c31 !important;
}

.activeBottomInner {
    border-top-right-radius: 30px;
    background-color: #25313e;
    border-bottom-color: white;
}

.activeBottomInnerDarkModeTheme {
    border-top-right-radius: 30px;
    background-color: #25313e;
    border-bottom-color: #222c31 !important;
}

.menuText {
    font-family: Poppins;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 1px;
    text-align: left;
    color: white;
}

.welcomeText {
    width: 224px;
    height: 36px;
    margin: 32px 74px 80px 18px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: white;
}

h1 {
    font-family: Poppins;
    font-weight: bold;
    padding-bottom: 10px;
}

h2, h3 {
    font-family: Poppins;
}

.h4 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: center;
    color: #636569;
}

.h1 {
    font-family: Poppins;
    font-weight: bold;
    font-size: 48px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: center;
    color: #121212;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.buttonBar {
    background-color: #eff1f5;
    border-radius: 22px;
}

.nav-link.active {
    color: #fff !important;
    background-color: #f47d30 !important;
    font-family: Poppins !important;
    font-size: 12px !important;
    font-weight: bold;
    border-radius: 24px !important;
    border: 0 none !important;
    outline: none !important;
    cursor: default !important;
}
.nav-link.disabled {
    font-weight: bold;
}

.nav-link.custom {
    color: #000;
    cursor: default;
    font-family: Poppins !important;
    font-size: 12px !important;
    font-weight: bold;
    cursor: pointer !important;
    border: 0 none !important;
    outline: none !important;
}

.signIn {
    margin: 0 12px 0 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: #25313e !important;
}

.signInDarkMode {
    margin: 0 12px 0 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: white !important;
}



.stationBtn {
    background-color: #f47d30;
    padding: 10px;
    cursor: pointer;
    border-radius: 24px;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
    border: none !important;
}

.stationBtn-disable {
    background-color: #ffd0bc;
    border: 2px solid #ffd0bc;
    pointer-events: none;
}

.center {
    width: 200px;
    height: 200px;
    align-self: center;
}

.divBtn {
    border: solid 2px #f47d30;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 1px;
    text-align: center;
    color: #5e5e5e;
}

.padding5 {
    padding: 48px !important;
}

.paddingt5 {
    padding: 48px 0px 48px 0px !important;
}

.paddingto5 {
    padding-top: 48px !important;
}

.paddingb5 {
    padding: 0px 0px 48px 0px !important;
}

.aircraftbg {
    background: url("./aircraft.jpg") no-repeat top center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.horStack {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
}

.navbarLogo {
    height: 0px;
}

.navbarLogoPM {
    height: 40px;
    padding-left: 5px;
}


.horStack > div {
    flex: 1 1 auto;
    text-align: left;
    margin: 5px; /* and that, will result in a 10px gap */
}

.dropdownSt {
    width: 280px;
}

.smdisplay {
    display: none;
}

.smdisplaynp {
    display: none;
}

.dashboard-lighterText {
    font-family: Poppins;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: justify;
}

.dashboard-listText {
    font-family: Poppins;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
}

    /*.dashboard-listText a {
        color: #000;
        text-decoration: none;
    }
    .dashboard-listText a:link {
        color: #000;
        text-decoration: none;
    }
    .dashboard-listText a:active {
            color: #000;
            text-decoration: none;
    }
    .dashboard-listText a:hover {
            color: #000;
            text-decoration: none;
    }*/

.lighterText {
    font-family: Poppins;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
}

.lightText {
    font-family: Poppins;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.0;
    letter-spacing: 0.5px;
    text-align: center;
}

.lightTextsm {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.0;
    letter-spacing: 0.5px;
    text-align: center;
}

.orang {
    color: #f47d30;
    font-weight: bold;
}

.tbborder {
    border: solid 0.5px #eff1f5;
    border-radius: 16px;
}

.dropzone {
    text-align: center;
    padding: 10px;
    background-color: #fff;
    color: #bdbdbd;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploadContainer {
    padding: 7px 5px;
    border: solid 1px #d6d8e7;
    border-radius: 8px;
}

.uploadFileLabel {
    margin: 0 27px 12px;
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.0;
    letter-spacing: 1px;
    text-align: center;
    color: #121212;
    padding-top:24px;
}

section {
    width: 100%;
    height: 100% !important;   
    border-radius: 8px;
}

.dropzoneInner {
    text-align: center;
    padding: 20px;
    border: 1px dashed #f47d30;
    background-color: #fff;
    color: #bdbdbd;
    width: 100%;
}
.traxfile-dropzoneInner {
    text-align: center;
    display: inline-block;
    border: 1px dashed #f47d30;
    background-color: #fff;
    color: #bdbdbd;
    width: 100%;
}

.dropzoneInner-border {      
    border: 1px dashed #f47d30;
    
}

.uploadFile-padding-left {
    margin-left: 30px;
}

.uploadFile-icon {
    width: 40px;
    padding-bottom: 8px;
  /*  margin-left: 20px;*/
}

.dropzone div {
    width: 100%;
    height: 100%;
}

transbox {
    background-color: #ffffff;
    opacity: 0.6;
    height: 20px;
}

.centerContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.loader {
    background-color: #fff;
    height: 100% !important;
}

.loaderPlanning {
    background-color: #fff !important;
    height: 100% !important;
    width: 100% !important;
}

.fullHeight {
    height: 100% !important;
    /*    height: 100% !important;*/
    /*background-color: #f47d30;*/
    margin: 0 !important;
    padding: 0 !important;
}

.centerBox {
    border-radius: 12px;
    background-color: white;
    max-width: 500px !important;
    text-align: center;
}

.centerBoxST {
    border-radius: 12px;
    background-color: white;
    max-width: 550px !important;
    text-align: center;
    padding: 30px;
}

.outerPlanning {
    display: flex;
    flex-wrap: wrap;
}

.tailsDiv {
    display: flex;
    flex-wrap: wrap;
    max-width: 400px;
    /* flex-direction: column; */
    height: 80vh;
    flex-basis: 100%;
    overflow-y: auto;
    align-content: baseline;
}

.tailsDiv2 {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    flex-direction: unset;
    justify-content: space-around;
    overflow-y: scroll;
    height: 40vh !important;
}

.tailsDiv3 {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    flex-direction: unset;
    justify-content: center;
    overflow-y: scroll;
    height: 40vh !important;
}

.planTitle {
    min-width: 360px;
}

.planningTitle {
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: #121212;
}

.underConstruction {
    float: left !important;
    display: none;
}

.cursor-pointer {
    cursor: pointer;
}

.sortUL {
}

    .sortUL > li {
        height: 50px;
        padding-top: 10px;
        list-style: none;
        padding-right: 8px;
        width: 250px;
        font-family: 'Poppins';
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        /* line-height: 1.8px; */
        letter-spacing: 0.5px;
        border-bottom: solid 0.5px #eff1f5;
        cursor: pointer;
    }

.form-control:focus {
    border-color: #f47d30 !important;
    box-shadow: 0 0 0 0.2rem rgba(244, 125, 48,0.25) !important;
}

.menuItemMx {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #636569;
}


.reportingFlex {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    padding: 0;
    flex-wrap:wrap;
}

.report-outer {
    width: 46%;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 1px 0 rgba(0, 0, 0, 0.07), 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #eff1f5;
    color: #121212;
    font-family: Poppins;
}

    .report-outer:hover {
        border: solid 1px #f47d30;
        color: #f47d30 !important;
    }


.report-title {
    /*height: 36px;*/
    align-self: stretch;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: #121212;
}



.report-subtitle {
    height: 32px;
    align-self: stretch;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #636569;
}

.reportIconStyle {
    color: #f47d30;
    height: 2.5em !important;
    width: 2.5em !important;
}

.flex-direction-column {
    flex-direction: column;
}

.report-padding{
    padding-right:32px !important;
}

.flex {
    display: flex;
}

.flex-space-between {
    justify-content: space-between;
}

.flex-left {
    justify-content: left;
}

.flex-center {
    justify-content: center;
}

.flex-end {
    justify-content: flex-end;
}

.flex-button {
    justify-content: flex-end;
}

.flex-align-end {
    align-items: flex-end;
}


.flex-align-center {
    align-items: center;
}

.flex-grow-1 {
    flex-grow: 1;
}

.buttonOuterDiv {
    margin-top: 25px;
}

.self-end {
    align-self: end;
}

.self-center {
    align-self: center;
}

.padding-bottom-10 {
    padding-bottom: 10px !important;
}

.contextMenuHeader > a {
    font-size: 22px;
    font-weight: bold;
    color: #919195 !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 20px !important;
}

    .contextMenuHeader > a > svg {
        height: 15px !important;
    }

.contextMenuHeader > button {
    padding-top: 0px !important;
    vertical-align: top !important;
    height: 20px !important;
    background-color: #fff !important;
    border: none !important;
    font-size: 22px;
    font-weight: bold;
    color: #919195 !important;
    padding-top: -5px;
}

.modalTextArea {
    border-radius: 4px !important;
    border: solid 1px #d6d8e7 !important;
    width: 100% !important;
    padding: 5px;
}

.crossButton {
    font-size: 15px;
    color: #616472;
    cursor: pointer;
}

.modal-content {
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    min-width: 600px;
}

.modal-title {
    font-family: Poppins !important;
    font-size: 24px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: 1px !important;
    text-align: left !important;
    color: #121212 !important;
    width: 100%;
}

.modalLabel {
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #121212;
}

.modal-header {
    border-bottom: none !important;
}

.modal-footer {
    border-top: none !important;
    padding-left: .25rem !important;
}


.round-empty-button {
    max-width: 172px;
    cursor: pointer;
    padding: 10px 39px;
    border-radius: 24px;
    border: solid 1px #f47d30;
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 1px;
    text-align: left;
    color: #f47d30;
    background-color: #fff;
}

    .round-empty-button:hover {
        background-color: #f47d30;
        color: #fff;
    }
.round-empty-button-auto {
    cursor: pointer;
    padding: 10px 39px;
    border-radius: 24px;
    border: solid 1px #f47d30;
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 1px;
    text-align: left;
    color: #f47d30;
    background-color: #fff;
}

    .round-empty-button-auto:hover {
        background-color: #f47d30;
        color: #fff;
    }

.round-fill-button-popup {
    max-width: 172px;
    cursor: pointer;
    padding: 12px 37px;
    border-radius: 24px;
    background-color: #f47d30;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 1px;
    text-align: left;
    color: #fff;
}
.round-fill-button-popup-large {    
    cursor: pointer;
    padding: 12px 37px;
    border-radius: 24px;
    background-color: #f47d30;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 1px;
    text-align: left;
    color: #fff;
}
.round-fill-button-popup:hover {
    background-color: #CF3C00;
}
.round-fill-button-popup-large:hover {
    background-color: #CF3C00;
}

.round-empty-button-popup {
    max-width: 172px;
    cursor: pointer;
    padding: 10px 39px;
    border-radius: 24px;
    border: solid 1px #f47d30;
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 1px;
    text-align: left;
    color: #f47d30;
    background-color: #fff !important;
}
    .round-empty-button-popup:hover {
        background-color: #f47d30 !important;
        color: #fff;
    }

    .round-fill-button {
        cursor: pointer;
        padding: 12px 63px;
        border-radius: 24px;
        background-color: #f47d30;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: 1px;
        text-align: left;
        color: #fff;
    }
    .round-fill-button-disable {    
        background-color: #ffd0bc;
        border: 2px solid #ffd0bc;
        pointer-events: none;
    }
    .round-fill-button:hover {
        background-color: #CF3C00;
    }
    .round-fill-button-disabled {
        padding: 12px 63px;
        border-radius: 24px;
        background-color: #d6d8e7;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: 1px;
        text-align: left;
        color: #fff;
    }

.notesOuterDiv {
    border-top: solid 1px #eff1f5;
    margin-top: 20px;
    flex-grow: 1;
}

.notesTitle {
    font-family: Poppins;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 1px;
    text-align: left;
    color: #121212;
}

.w-12 {
    width: 12% !important;
    word-wrap: break-word;
}

.w-11 {
    width: 11% !important;
    word-wrap: break-word;
}

.w-6 {
    width: 6% !important;
    word-wrap: break-word;
}

.w-14 {
    width: 14% !important;
    word-wrap: break-word;
}

.w-17 {
    width: 17% !important;
    word-wrap: break-word;
}

.w-22 {
    width: 22% !important;
    word-wrap: break-word;
}

.w-8 {
    width: 8% !important;
    word-wrap: break-word;
}

.w-26 {
    width: 26% !important;
    word-wrap: break-word;
}

.w-15 {
    width: 15% !important;
    word-wrap: break-word;
}

.w-10 {
    width: 10% !important;
    word-wrap: break-word;
}

.w-20 {
    width: 20% !important;
    word-wrap: break-word;
}

.w-30 {
    width: 30% !important;
    word-wrap: break-word;
}

.w-35 {
    width: 35% !important;
    word-wrap: break-word;
}

.w-25 {
    width: 25% !important;
    word-wrap: break-word;
}

.w-40 {
    width: 40% !important;
    word-wrap: break-word;
}

.w-50 {
    width: 50% !important;
    word-wrap: break-word;
}

.w-70 {
    width: 70% !important;
    word-wrap: break-word;
}

.w-100 {
    width: 100% !important;
    word-wrap: break-word;
}


.w-5 {
    width: 5% !important
}

.btn-buttonFilter > svg {
    height: 15px;
}

.backGroundWhite {
    background-color: #fff !important;
}

.overlayFilter {
    width: 300px;
}

.planningSubTitle {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #121212;
}

.worksheetSubTitle {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #636569;
}

.notesAdddedBy {
    font-family: Poppins;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #919195;
}

.notesText {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #636569;
}

.notesText-tasks{
    padding-left:32px;
    padding-top:4px;
}

.partsOuterDiv {
    border-top: solid 1px #eff1f5;
    margin-top: 20px;
    flex-grow: 1;
}

.hideInMax {
    display: none;
}

.showInMax {
    display: block;
}

.hide{
    display:none;
}

.calendar-custom-input {
    padding: 4px;
    color: #919195;
    font-family: Poppins;
    font-size: 16px;
    background-color: #fff;
    border: solid 2px #eff1f5;
    border-radius: 5px;
}

    .calendar-custom-input:hover {
        border: solid 2px #f47d30;
    }

    .calendar-custom-input:focus {
        border: solid 2px #f47d30 !important;
    }

.calendar-custom-input-width {
    width: 320px !important;
}

    .calendar-custom-input-width > svg {
        float: right !important;
    }

.react-datepicker__header {
    background-color: #fff !important;
    border: none !important;
}

.react-datepicker__current-month {
    color: #f47d30 !important;
    font-family: Poppins !important;
    /* text-align: left !important;
    padding-left: 8px !important;*/
    text-align: center !important;
    font-size: 16px !important;
}

.react-datepicker__day-name {
    font-size: 13px !important;
    font-weight: 600 !important;
    /*  color: rgba(60,60,60,0.3) !important;*/
}

.react-datepicker {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 1px 0 rgba(0, 0, 0, 0.07), 0 1px 1px 0 rgba(0, 0, 0, 0.05) !important;
    border: none !important;
    color: #636569 !important;
    font-size: 14px !important;
    font-family: Poppins !important;
}

.react-datepicker__day--selected {
    background-color: #ffd0bc !important;
    color: #f47d30 !important;
    border: none !important;
}

    .react-datepicker__day--selected:focus {
        background-color: #ffd0bc !important;
        color: #f47d30 !important;
        border: none !important;
    }

.react-datepicker__day:hover {
    background-color: #ffd0bc !important;
    color: #f47d30 !important;
}

.react-datepicker * {
    outline: none;
}

.tailsInstance {
    max-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 180px;
    cursor: pointer;
}

.current-tag {
    height: 30px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 16px;
    border-radius: 38px;
    background-color: #ffd0bc;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #f47d30;
}


.select-tag {
    height: 30px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 16px;
    border-radius: 38px;
    background-color: #cf3c00;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #fff;
}

.tailsInstancePopUp {
    max-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 180px;
    cursor: pointer;
}

.tailsInstance2 {
    max-width: 370px;
    display: flex;
    
    flex-direction: column;
    justify-content: space-between;
    min-height: 162px;
    max-height: 200px;
    cursor: pointer;
    padding:10px;
}


.checkboxContent {
    height: 15px;
    align-self: flex-end;
    background: white;
    /* background-blend-mode: overlay; */
    z-index: 2;
    /*    margin-right: -15px;*/
}

.otherContent {
    overflow: auto;
    flex: 1;
    /*    margin-top: -10px;*/
    border: solid 2px #eff1f5;
    border-radius: 12px;
    padding: 10px;
    overflow-x: hidden;
}

.otherContent2 {
    overflow: auto;
    flex: 1;
    /*    margin-top: -10px;*/
    border: solid 2px #eff1f5;
    border-radius: 12px;
    padding: 10px;
    overflow-x: hidden;
}

.otherSelected {
    overflow: auto;
    flex: 1;
    /*    margin-top: -10px;*/
    border: solid 2px #f47d30;
    border-radius: 12px;
    padding: 10px;
    overflow-x: hidden;
}

.otherContent:hover {
    border: solid 2px #f47d30;
    border-radius: 12px;
}

.containerCheckBox {
    margin: 0 auto;
}

.workLoadInstance > section {
    height: 100% !important;
}

section {
    display: table;
    /*width: 343px;*/
    width: 322px;
    height: auto !important;
    overflow-x: hidden;
}

    section > * {
        display: table-row;
    }

    section .col {
        display: table-cell;
        flex-basis: 0 !important;
        flex-grow: 1 !important;
        max-width: 100% !important;
    }

.titleTailInstance {
    width: 117px;
    height: 48px;
    font-family: Poppins;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: #121212;
}


.titleTailInstance2 {
    width: 117px;
    height: 30px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: #121212;
}


.tailNumberInstance {
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: #121212;
}

.css-1wa3eu0-placeholder{
    margin-left:10px !important;
}

.css-1uccc91-singleValue {
    margin-left: 10px !important;
}

.report-padding-2 {
    padding-right: 20px !important;
}

.middleHeaderInstance {
    font-family: Poppins;
    font-size: 14px;
    font-weight: bolder;
    letter-spacing: 0.5px;
    text-align: left;
    color: #121212;
}

.rightHeaderInstance {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: #636569;
}

.textHeaderInstance {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: #121212;
}

.round {
    position: relative;
}

    .round label {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        height: 28px;
        left: 0;
        position: absolute;
        top: 0;
        width: 28px;
    }

        .round label:after {
            border: 2px solid #fff;
            border-top: none;
            border-right: none;
            content: "";
            height: 6px;
            left: 7px;
            opacity: 0;
            position: absolute;
            top: 8px;
            transform: rotate(-45deg);
            width: 12px;
        }

    .round input[type="checkbox"] {
        visibility: hidden;
    }

        .round input[type="checkbox"]:checked + label {
            background-color: #66bb6a;
            border-color: #66bb6a;
        }

            .round input[type="checkbox"]:checked + label:after {
                opacity: 1;
            }


.detailContent {
    margin-left: 50px;
    border-left: solid 1px #d6d8e7;
    padding-left: 30px;
    flex: 1;
    height: 80vh;
    overflow-y: auto;
}

.mddrBox {
    margin: 0 1px 16px 0;
    padding: 24px 21.5px 24px 24px;
    border-radius: 12px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 1px 0 rgba(0, 0, 0, 0.07), 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #eff1f5;
}

.mddrStatusBox {
    padding-top: 15px;
}

.mddrBox .title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #121212;
}

.mddrBox .subTitle {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #636569;
}
.mddrBox .subTitle-black {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #000;
}

.mddrBox .descriptionText {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #919195;
    max-width: 100% !important;
}

.hideIP {
    display: block;
}

.hideIPFlex {
    display: flex;
}

.statusPad1 {
    padding: 10px 32px;
    border-radius: 38px;
    background-color: rgba(13, 186, 97, 0.1);
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #0dba61;
    display: inline-block;
}

.statusPadP {
    padding: 5px 32px;
    border-radius: 38px;
    background-color: rgba(13, 186, 97, 0.1);
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #0dba61;
    display: inline-block;
}

.statusPadPs {
    padding: 10px 16px;
    border-radius: 38px;
    background-color: rgba(13, 186, 97, 0.1);
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #0dba61;
    display: inline-block;
}

.statusPad-auto {
    text-align: center !important;
    /*width: 156px;*/
    height: 40px;
}

.statusPad2 {
    padding: 10px 16px;
    border-radius: 38px;
    background-color: rgba(255, 0, 0, 0.1);
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 1px;
    text-align: left;
    color: #e10412;
    display: inline-block;
}

.statusPad3 {
    padding: 10px 32px;
    border-radius: 38px;
    background-color: #eff1f5;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 1px;
    text-align: left;
    color: #919195;
    display: inline-block;
}
.statusPad4 {
    padding: 10px 16px;
    border-radius: 38px;
    background-color: #dfe7f2;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 1px;
    text-align: left;
    color: #2F80ED;
    display: inline-block;
}
.react-overlay-loader-spinner:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    margin-top: -30px;
    margin-left: -20px;
    border-radius: 50%;
    border: 3px solid #eee;
    border-top-color: #07d;
    animation: react-overlay-loader-spinner 0.8s linear infinite;
}

.partNameBox {
    width: 403px;
}

.text-center{
    text-align: center !important;
}

.partsModal {
    min-width: 700px !important;
}

.partsModalXL {
    min-width: 90% !important;
}

.lh3 {
    line-height: 3.0;
}

.smallModal {
    max-width: 486px !important;
}

    .smallModal .modal-content {
        min-width: 486px !important;
    }

.customSelect {
    width: 190px !important;
}

.partsForm label {
    margin: 0 29px 13px 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #121212;
}

.partsForm span {
    color: red;
}

.partsForm textarea {
    padding: 11.8px 3.8px 5.7px 16.9px;
    border-radius: 4px;
    border: solid 1px #d6d8e7;
    width: 100%;
}

.outerDivMxR {
    padding: 25px 20px 20px 20px;
    border-radius: 12px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 1px 0 rgba(0, 0, 0, 0.07), 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #eff1f5;
    margin-bottom: 20px;
}

.titleMxR {
    font-family: Poppins;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 1px;
    text-align: left;
    color: #121212;
}

.headerMxR {
    height: 56px;
    border: solid 0.5px #eff1f5;
    border-radius: 10px 10px 0px 0px;
    background-color: #eff1f5;
    padding: 14px 14px 14px 14px;
    margin-top: 20px;
}

.rowMxR {
    min-height: 56px;
    padding: 14px 14px 14px 14px;
    border: solid 0.5px #eff1f5;
    border-radius: 0px 0px 10px 10px;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
}

    .rowMxR > div > div > svg , .rowMxR > div > svg {
        color: #f47d30;
        /* height: 20px;*/
        font-size: 1.2em;
    }

.inner-div-WIP > label > svg {
    color: #f47d30 !important;
    height: 20px;
    font-size:32px;
}


.searchBox {
    height: 48px;
    width: 0px !important;
    margin: 0 0 0 0;
    margin-right: 10%;
    margin-left: 10%;
    padding: 0;
    border-radius: 5px;
    border: solid 1px #eff1f5;
    background-color: #ffffff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #636569;
}



.button-width {
    margin: 0px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 18%;
    padding-right: 18%;
    width: 210px !important;
}
.add-user-button-width {
    margin-right: 40px !important;
    padding-top: 0;
    padding-bottom: 0;
    width: 195px !important;
}


.workloadpopOver-filter {
    width: 260px !important;
    min-width: 260px !important;
    max-width: 260px !important;
    top: 0;
    bottom: 50px;
}

    .workloadpopOver-filter > .popover {
        width: 260px !important;
        min-width: 260px !important;
        max-width: 260px !important;
        top: 10px !important;
        will-change: transform !important;
        transform: translate3d(1044px, 29px, 0px) !important;
        right: 10px !important
    }

.dtpicker {
    width: 150px !important;
    min-width: 150px !important;
    max-width: 150px !important;
}

.popOver-filter {
    width: 350px !important;
    min-width: 350px !important;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    margin-right: 10px !important;
    max-width: 350px !important;
    top: 0;
    bottom: 50px;
}

    .popOver-filter > .popover {
        width: 350px !important;
        min-width: 350px !important;
        max-width: 350px !important;
        top: 0;
        bottom: 50px;
        margin-bottom: 50px;
        right: 0;
    }

        .popOver-filter > .popover .arrow {
            display: none;
        }

        .popOver-filter > .popover > .popover-inner > .btn-holder {
            justify-content: flex-end;
            display: flex;
            position: absolute;
        }

.save-popover-button {
    width: 173.7px;
    height: 42px;
    margin: 0 0 0 29.1px;
    padding: 12px 19.3px;
    border-radius: 24px;
    background-color: #f47d30;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 1px;
    text-align: left;
    color: #ffffff;
}

.save-button-width-add {
    width: 100px !important;
    padding-left: 29px !important;
    margin-left: 20px !important;
}

.noPadding {
    padding: 0 !important;
}

.noRightPadding {
    padding-right: 0 !important;
}

.noLeftPadding {
    padding-left: 0 !important;
}

.colUser {
    flex: 0 0 50% !important;
    max-width: 50% !important;
    position: relative !important;
}

.paddingTopUserDetails {
    padding-top: 15px !important
}

.paddingRightDoubleUserDetails {
    padding-right: 30px !important
}


.showMx {
    display: none;
}


.colorLegendModal {
    width: 350px !important;
    height: 350px !important;
    margin-top: 150px !important;
    margin-right: 50px !important;
    border-radius: 8px !important;
    background-color: #ffffff !important;
    float: right !important;
}

.colors-modal-content {
    width: 350px !important;
    height: 350px !important;
    /*margin: 1.3px 0 6px 235px !important;
    padding: 24px 4.9px 26px 20.1px !important;*/
    border-radius: 8px !important;
    background-color: #ffffff !important;
    min-width: 315px !important;
}

.informationModal {
    width: 825px !important;
    height: 934px !important;
    border-radius: 8px !important;
    background-color: #ffffff !important;
}

.information-modal-content {
    width: 825px !important;
    height: 934px !important;
    border-radius: 8px !important;
    background-color: #ffffff !important;
}

.Flightboard-Boad-Update {
    height: 36px;
    margin: 10px 191px 10px 215px;
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: center;
    color: #121212;
}

.info-section {
    /*width: 761px;*/
    height: 184px;
    margin: 0 0 15.5px;
    padding: 24px;
    border-radius: 12px;
    border: solid 1px #eff1f5;
    background-color: #eff1f5;
}

.info-section-large {
    height: 200px;
}


.custom-info {
    width: 569px !important;
    height: 61px !important;
    text-align: center !important;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #232d3d;
    background-color: #d6d8e7 !important;
}

.flight-header {
    padding: 2px 10px 10px 58px;
    background-color: #f47d30 !important;
    height: 30px;
    /*margin: 0.5px 45.3px 33.5px 36.8px;*/
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: #121212;
    text-align: center;
}

.row-bkg-Color {
    background: #303e46;
}

.alt-row-bkg-Color {
    background: #222c31;
}

.flightDetails-data {
    height: 29px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #ffffff;
    padding: 2px 10px 10px 58px;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.row-margin-left {
    margin-left: 0px !important;
    padding-left: 0px !important;
}

.CVG-label {
    width: 70.9px;
    height: 36px;
    margin: 0 432.5px 0 20px;
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: #ffffff;
}

.margin-IO-30 {
    margin-right: 30px;
}

.align-right {
    text-align: right;
}

.dot {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;
    margin: 16px 16px 0 16px;
}

.green-dot {
    background-color: #0dba61;
}

.blue-dot {
    background-color: #2f80ed;
}

.yellow-dot {
    background-color: #f2c94c;
}

.red-dot {
    background-color: #e10412;
}

.colors-legend-labels {
    height: 29px;
    margin: 0 0 0 16px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #636569;
}

.text-decoration {
    text-decoration: underline !important;
}

.information-title {
    height: 29px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #121212;
}

.information-heading {
    /*width: 161px;*/
    height: 24px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 1px;
    text-align: center;
    color: #121212;
}

.text-align-center {
    text-align: center !important;
}

/*.popover {
    max-width: 350px !important;
    height: 570px !important;*/
/*top: 0;
    bottom: 0;
    right:0;*/
/*}*/

.img-customer-logo {
    width: 87.9px;
    height: 24.6px;
    padding-left: 5px;
}

.customer-Logo-tail-div {
    width: 85px;
    height: 32px;
    /*margin: 32px 0 0;*/
    padding: 3.7px 0 3.7px 5px;
}

.left-padding {
    padding-left: 15px !important;
}

.left-padding-20 {
    padding-left: 20px !important;
}

.time-station-div {
    width: 50px;
    height: 32px;
    /* margin: 32px 0 0;*/
    padding: 3.7px 0 3.7px 5px;
}

.status-div {
    width: 20px;
    height: 32px;
    /*margin: 32px 22.8px 0 321.5px;*/
    padding: 3.7px 0 3.7px 5px;
}

.departure-station-detail-div {
    width: 107px;
    height: 32px;
    padding: 3.7px 0 3.7px 5px;
}

.departure-notes-div {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #b4b7bf;
    padding: 5.7px 0 3.7px 5px;
    margin-left: 20px !important;
}

.min-width-300 {
    min-width: 300px !important;
}

.min-width-150 {
    min-width: 150px !important;
    max-width: 450px !important;
}

.extended-groundtime-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 95%;
}

.show-extended-ground-Flex {
    display: flex;
}

.hide-extended-groundFlex {
    display: none;
}

.exGTInstance {
    flex-basis: 49%;
    flex-direction: column;
    cursor: pointer;
    /*height: 70px;*/
    margin-right: 5px !important;
    margin-bottom: 5px !important;
}



.lbl-tailnumber {
    width: 135px !important;
}

.lbl-grounddetails {
    width: 210px !important;
}

.main-heading-filter {
    width: 214.2px !important;
    height: 24px !important;
    margin-top: 30px !important;
    margin-left: 30px !important;
    font-family: Poppins !important;
    font-size: 18px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.33 !important;
    letter-spacing: 1px !important;
    text-align: left !important;
    color: #121212 !important;
}
.workload-main-heading-filter {
    width: 214.2px !important;
    height: 24px !important;
    margin-top: 30px !important;
    margin-left: 12px !important;
    font-family: Poppins !important;
    font-size: 18px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.33 !important;
    letter-spacing: 1px !important;
    text-align: left !important;
    color: #121212 !important;
}
.popover-body-padding-top {
    padding-top: 0px !important;
}


.profileBox {
    position: relative;
    cursor: pointer;
}

.profilePicture {
    width: 106px;
    border-radius: 50%;
}

.profilePictureSelector {
    width: 176px;
    border-radius: 50%;
}

.profileBox::after {
    position: absolute;
    background: url('icons/edit/edit photo.png');    
    content: ' ';
    height: 48px;
    width: 48px;
    margin-top: 60px;
    margin-left: -33px;
}

.spacer {
    padding-top: 30px;
}

.spacer2 {
    clear: both;
}

.profle-file-input {
    color: transparent;
    width: 227px;
}

    .profle-file-input::-webkit-file-upload-button {
        visibility: hidden;
    }

    .profle-file-input::before {
        content: 'ADD PHOTO';
        display: inline-block;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        background-color: #f47d30 !important;
        cursor: pointer;
        padding: 12px 63px;
        border-radius: 24px;
        background-color: #f47d30;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: 1px;
        text-align: left;
        color: #fff;
    }



    .profle-file-input:active {
        outline: 0;
    }


.data-customer-filter {
    height: 29px !important;
    margin: 0 0 0 17px !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.8 !important;
    letter-spacing: 0.5px !important;
    text-align: left !important;
    color: #636569 !important;
}

.sub-heading-filter {
    height: 17px !important;
    width: 300px !important;
    margin: 0 266.1px 12px 30px !important;
    font-family: Poppins !important;
    font-size: 14px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.2 !important;
    letter-spacing: 0.5px !important;
    text-align: left !important;
    color: #121212 !important;
}

.input-group-search-filter {
    width: 30% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
}

.input-group-text-search-filter {
    background-color: white !important;
    border: 1px solid #eff1f5 !important;
    border-radius: 5px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-left: 0px !important;
}

.input-group-text-search-container {
    border-right: 0px !important;
}

.line2 {
    background-color: #fffffe;
}


.select-custom-border-0 {
    width: 160px !important;    
    margin-left:-15px;
}

    .select-custom-border-0 .css-1okebmr-indicatorSeparator {
        width: 0px !important;
    }

  /*  .select-custom-border-0 .css-g1d714-ValueContainer {
        padding-left: 0px !important;
    }*/

    .select-custom-border-0 .css-1uccc91-singleValue {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.5px;
        text-align: left;
        color: #636569 !important;
    }

.select-custom-deferral {
    width: 240px !important;
}

.markAog {
    height: 25px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #f47d30;
}

.round-fill-button-2 {
    max-width:172px;
    cursor: pointer;
    padding: 12px 12px;
    border-radius: 24px;
    background-color: #f47d30;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 1px;
    text-align: left;
    color: #fff;
}

    .round-fill-button-2:hover {
        background-color: #CF3C00;
    }
.worksheetOuter {
    padding: 24px 32px;
    border-radius: 12px;
    border: solid 1px #eff1f5;
    background-color: #fff;
}

.worksheetTitle {
    flex-grow: 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: #121212;
}

    .worksheetTitle > svg {
        width: 12px;
        padding-bottom: 5px;
    }

.dimTitle {
    height: 36px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: #919195;
}

.workSheetHeader {
    margin: 15px;
    padding-top: 20px;
}

.workSheetRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between !important;
}

.workSheetColumn {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #636569;
    min-height: 42px !important;
}

    .workSheetColumn > div > span {
        width: 100px;
        display: inline-block;
        height: 29px;
        flex-grow: 0;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.5px;
        text-align: left;
        color: #121212;
    }

.flightPad {
    width: 92px !important;
    height: 23px !important;
    align-items: center !important;
    margin: 3px 0 3px 42.4px !important;
    padding: 4px 16px !important;
    border-radius: 38px !important;
    background-color: #eff0f6;
    font-family: Poppins;
    font-size: 10px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #616472;
    display: unset !important;
}

.exWorkloadInstance {
    flex-basis: 25%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.content-margin-top {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
}

.workload-tail-title {
    width: 67px;
    height: 24px;
    margin: 1.5px 6.5px 0 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #121212;
}

.workload-company-title {
    width: 79px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #121212;
}

.workload-details-title {
    width: 83px;
    height: 29px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #636569;
}

.workload-details-font {
    font-weight: 600;
}

.workload-remaing-color {
    color: #121212;
}

.pagination-workload {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    display: flex !important;
    justify-content: center !important;
}

.rc-pagination-item {
    border-radius: 50px !important;
    height: 40px !important;
    min-width: 40px !important;
    margin: 0 6px !important;
    padding: 5px !important;
    border-radius: 99px !important;
    background-color: #eff0f6 !important;
    margin-top: 10px !important;
    border: 1px solid #f47d30;
    color: #f47d30;
}
    .rc-pagination-item:focus a, .rc-pagination-item:hover a {
        color: #f47d30 !important;
    }
    .rc-pagination-item:focus, .rc-pagination-item:hover {
        border: solid 2px #f47d30 !important;
        color: #f47d30 !important;
    }

.rc-pagination-item-active {
    border: solid 2px #f47d30 !important;
    background-color: white !important;
}

.rc-pagination-prev button {
    width: 170px !important;
    height: 40px !important;
    display: flex !important;
    flex-direction: column !important;
    margin: 0 34px 0 0 !important;
    border-radius: 24px !important;
    border: solid 2px #f47d30 !important;
    color: #f47d30 !important;
}
    .rc-pagination-prev button:hover {
        background-color: #f47d30 !important;
        color: #fff !important;
    }
    .rc-pagination-prev button:before {
        justify-content: center !important;
        font-family: 'Font Awesome 5 Free';
        content: "\f060" !important;
        text-align: center !important;
        height: 18px;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: 1px;     
        margin-left: 20px;
        margin-top: 8px;
      
    }

    .rc-pagination-prev button:after {
        justify-content: center !important;
        font-family: Poppins;
        content: ' PREVIOUS' !important;
        text-align: center !important;
        height: 18px;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: 1px;
        margin-top: -18px;
        margin-left: 46px;
       
    }

.rc-pagination-next button {
    width: 140px !important;
    height: 40px !important;
    display: flex !important;
    flex-direction: column !important;
    margin: 0 0 0 34px !important;
    border-radius: 24px !important;
    border: solid 2px #f47d30 !important;
    text-align: center !important;
    align-self: center !important;
    color: #f47d30 !important;
}

    .rc-pagination-next button:hover {
        background-color: #f47d30 !important;
        color: #fff !important;
    }

    .rc-pagination-next button:before {
        margin-top: 8px;
        margin-left: 33px;
        justify-content: center !important;
        font-family: Poppins;
        content: 'NEXT' !important;
        text-align: center !important;
        height: 18px;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: 1px;
        /*   color: #f47d30 !important;*/
    }
        .rc-pagination-next button:after {
            margin-top: -17px;
            margin-left: 80px;
            justify-content: center !important;
            font-family: 'Font Awesome 5 Free';
            content: "\f061" !important;
            text-align: center !important;
            height: 18px;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: 1px;
          /*  color: #f47d30 !important;*/
        }
             
.rc-pagination-disabled button:before {
    color: #b4b7bf !important;
}
.rc-pagination-disabled button:after {
    color: #b4b7bf !important;
}

.rc-pagination-disabled button {
    border-color: #b4b7bf !important;
  
}
.rc-pagination-disabled button:hover {    
    background-color: #fff !important;
}

.blockout-Status {
    width: 80px;
    height: 25px;
    margin: 6px 30px 3px 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #919195;
}

.status-inactive {
    width: 75px !important;
    width: 96px;
    height: 23px;
    display: flex;
    flex-direction: column;
    margin: 0 17px 2.5px 6.5px;
    padding: 4px 16px;
    border-radius: 38px;
    background-color: #eff0f6;
    font-family: Poppins;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: center;
    color: #616472;
}

.flight-status {
    width: 95.8px;
    height: 23px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 2.5px 8px;
    padding: 4px 16px;
    border-radius: 38px;
    background-color: rgba(13,186,97,.1);
    align-self: center;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: center;
    color: #0dba61;
}
.flight-status-nostatus {
    margin-right: 17px !important;
}
.flight-status-delayed {
    color: #000 !important;
    background-color: #e10412 !important;
}
.flight-status-AOG {
    color: #000 !important;
    background-color: #e10412 !important;
    margin-right: 17px !important;
}
.flight-status-routine {
    color: #fff !important;
    background-color: #2f80ed !important;
    margin-right: 17px !important;
}

.flight-status-critical {
    color: #000000 !important;
    background-color: #f2c94c !important;
    margin-right: 17px !important;
}
.flight-status-mxready {
    color: #0dba61 !important;
    background-color: rgba(13,186,97,.1);
    margin-right: 17px !important;
}

.worksheet-button-width {
    width: 130px !important;
    padding: 9px 15px !important;
    height: 34px !important;
}

.worksheet-button-full-width {
    width: 100% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
    height: 34px !important;
}

.worksheet-section-height {
    min-height: 350px !important;
    height: 350px !important;
}

.worksheet-cancel-section-height {
    min-height: 310px !important;
    height: 310px !important;
}

.worksheet-uploadFile-icon {
    width: 40px;
    margin-bottom: 8px;
    margin-top: -20px;
}


hr {
    border: 0;
    clear: both;
    display: block;
    width: 96%;
    background-color: #eff1f5;
    height: 1px;
}

.margin-bottom-6 {
    margin-bottom: 6px !important;
}

.workload-label {
    width: 128px;
    height: 36px;
    margin: 1.8px 13.4px 12px 0;
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: #121212;
}

.records-count-label {
    height: 21px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #919195;
}

.width-100 {
    width: 100% !important;
}

.workLoadInstance {
    max-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 180px;
    cursor: pointer;
}

.text-align-right {
    text-align: right !important;
    margin-right: 6%; /*!important;*/
}

.left-padding-10 {
    padding-left: 10px !important;
}

.width-search-workload {
    width: 550px !important;
}

.display-none {
    display: none !important;
}

.max-width-50px {
    max-width: 50px !important;
}

.filter-checkbox {
    width: 23.7px;
    height: 24px;
    margin: 3px 12.3px 2px 15px;
    border-radius: 5px;
    border: solid 1px #d6d8e7;
}

.filter-checkbox-label {
    height: 29px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #636569;
    align-items: center;
    justify-content: center;
}
.checkbox-wrapper {  
    height: 30px;
    width: 227px;
    position: relative;    
    display: flex;
    align-items: center;
    /* justify-content: space-around;*/
}

div[class="checkbox-wrapper"] input[type="checkbox"] {
    width: 23.7px;
    height: 24px;
    background-color: #fff;
    border: solid 1px #d6d8e7;
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    appearance: none;
    -moz-appearance: none;
    margin: 3px 12.3px 2px 15px;
}
    div[class="checkbox-wrapper"] input[type="checkbox"]:after {
        background-color: #f47d30;    
        width: 23.7px !important;
        height: 24px !important;
        border-radius: 5px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: '\f00c';
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        padding-left: 3px;
        display: none;
    }
    div[class="checkbox-wrapper"] input[type="checkbox"]:checked:after {
        display: block;
    }

.workloadpopOver-filter > .popover > .popover-inner > .workload-filter-popover-header {
    height: 35px !important;
    font-family: Poppins !important;
    font-size: 14px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.8 !important;
    letter-spacing: 0.5px !important;
    text-align: left !important;
    color: #121212 !important;
}

.react-datepicker__close-icon {
    right: 25px !important;
}

.react-datepicker__triangle {
    border-bottom-color: white !important;
}

.blockout-button {
    background-color: white !important;
    border: none !important;
}

.margin-bottom-30 {
    margin-bottom: 30px !important;
}

.workload-section-text-align-right {
    text-align: right !important;
    margin-right: 10px !important;
}

.width-workload-header-100 {
    min-width: 100px !important;
    max-width: 110px !important;
    width: 110px !important;
}

.addmxrequest {
    text-align: center !important;
}

    .addmxrequest > .vector {
        border: none !important;
    }

.addmxrequest-button {
    height: 24px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: #f47d30;
}

.height-auto {
    height: auto !important;
}

.div-logs-worksheet {
    flex-grow: 0;
    padding-left: 10px;
    border-radius: 4px;
    border: solid 1px #d6d8e7;
    max-height: 600px !important;
    min-height: 100px !important;
    overflow-y: auto !important;
}

.hours-ago {
    height: 15px;
    flex-grow: 0;
    font-family: OpenSans;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #b4b7bf;
}

.logs-description {
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #636569;
    word-break:break-word;
}

.hide-in-big-screen {
    display: none !important;
}

.btn-border-bottom-right-radius {
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
}

.mxrequest-save-div {
    min-width: 650px !important;
}

.padding-top-15 {
    padding-top: 15px !important;
}

.photo_inbound {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #636569 !important;
}

.log-label {
    margin: 0 29px 13px 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #121212;
}

.fa-trash:before {
    height: 22px;
    color: #f47d30 !important;
}

.text-right-align {
    text-align: right !important;
}

.react-datepicker-popper {
    z-index: 9999 !important;
}

.fa-trash-alt {
    color: #f47d30 !important;
}

.bs-popover-auto {
    height: fit-content !important;
}

.bold-text {
    font-weight: bold !important;
}

.planning-checkbox {
    width: 23.7px;
    height: 24px;
    margin: 0px 0px 2px -10px;
    border-radius: 5px;
    border: solid 1px #d6d8e7;
}

.print-schedule-style {
    padding: 9px 6px !important;
    width: 167px !important;
    height: 36px !important;
}

.right-padding-5-percent {
    padding-right: 5% !important;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }

        [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 24px;
            height: 24px;
            margin: 0px 0px 2px 0px;
            border: 1px solid #f47d30;
            border-radius: 100%;
            background: #fff;
        }

        [type="radio"]:checked + label:after,
        [type="radio"]:not(:checked) + label:after {
            content: '';
            width: 14px;
            height: 14px;
            background: #f47d30;
            position: absolute;
            top: 5px;
            left: 5px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }

        [type="radio"]:not(:checked) + label:after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
        }

        [type="radio"]:checked + label:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }

.react-calendar__tile {
    padding: 0.5em 0.0em !important;
}
.react-calendar__tile--active {
    background-color: #ffd0bc !important;
    color: #f47d30 !important;
    border-radius: 0px;
}
.react-calendar__tile--hasActive {
    background-color: #ffd0bc !important;
}

.react-calendar__tile--hover {
    /*background: #ffd0bc !important;*/
}
button[class='react-calendar__tile--hover'][disabled]
{
    background: #fff !important;
}

.react-calendar__tile--selected {
    background-color: #ffd0bc !important;
    color: #f47d30 !important;
    border: none !important;
}

    .react-calendar__tile--selected:focus {
        background-color: #ffd0bc !important;
        color: #f47d30 !important;
        border: none !important;
    }

.react-calendar__tile:hover {
    background-color: #ffd0bc !important;
    color: #f47d30 !important;
    border-radius: 0px;
}

.react-daterange-picker__button {
    color: #919195 !important;  
}
.react-daterange-picker__button__icon {
    stroke: #919195 !important;
}
.react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
    stroke: #919195 !important;
}

/*.react-daterange-picker__wrapper {
    border: thin solid #f47d30;
}*/
.react-calendar {
    border: none !important;
    box-shadow: 0px 1px 3px 0px 
                rgba(0,0,0,.1),0 2px 1px 0 
                rgba(0,0,0,.07),0 1px 1px 0 
                rgba(0,0,0,.05) !important;
    
}
.react-calendar {
    font-family: Poppins !important;
}
.react-calendar__navigation {
    height: 32px;
    margin-bottom: 5px !important;
/*    border: 1px solid #000;*/
}

    .react-calendar__navigation button {      
        color: #f47d30 !important;
        font-family: Poppins !important;
        text-align: center !important;
        font-size: 16px !important;
        font-weight: bold !important;
    }

    .react-calendar__navigation button:hover {
    color:#fff !important;
    background: #f47d30 !important;
    }
.react-calendar__month-view {
cursor: pointer;
}
.react-calendar__month-view__weekdays {
    text-transform: none !important;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.4em !important;
    color: #000 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
}
.react-calendar__month-view__days__day--weekend {
    color: #000 !important;
}
    .react-calendar__month-view__days__day:disabled {
        color: #C6C6C6 !important;
    }
.react-calendar__month-view__days__day:hover:disabled {
    background: #f0f0f0 !important;
}

.react-calendar__navigation button[disabled] {
    display: none !important;
}


abbr[title] {
    text-decoration: none !important;
}

.header-report {
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #121212;
}

.react-daterange-picker__wrapper {
    height: 42px !important;
    background-color: hsl(0,0%,100%) !important;
    border-radius: 3px !important;
    border-style: solid !important;
    border-width: 1px !important;
    border-color: gray !important;
    color: #919195 !important;
    display: flex;
    flex-direction: row;  
}

    .react-daterange-picker__wrapper > .react-daterange-picker__inputGroup:nth-child(1) {
        order: 2;                
    }
    .react-daterange-picker__wrapper > .react-daterange-picker__range-divider {        
        order: 3;
    }
    .react-daterange-picker__wrapper > .react-daterange-picker__inputGroup:nth-child(3) {
        order: 4;        
    }
    .react-daterange-picker__wrapper > button {        
        order: 1;
    }
   
    .react-daterange-picker__inputGroup__input {
        color: #919195 !important;       
    }

.rowMxR-reports {
    height: 56px;
    flex-grow: 0;
    padding: 14px 14px 14px 14px;
    box-shadow: inset 0 -1px 0 0 rgba(102, 102, 102, 0.08);
    background-color: #ffffff;
}

.deferralReport input[type="checkbox"]:checked + label {
    background-color: #f47d30;
    border-color: #f47d30;
}
    .deferralReport input[type="checkbox"]:checked + label:after{
        opacity:0;
    }

.float {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 40px;
    background-color: #f47d30;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    cursor: pointer;
}
@media only screen and (max-width: 1025px) {
    .flCol {
        border: 0 none;
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .rowMxR {
        font-size: 14px !important;
    }

    .planningSubTitle {
        font-size: 16px !important;
    }

    .form-border {
        border: solid 1px #d6d8e7 !important;
        width: 160px !important;
        height: 38px;
    }

    .workSheetHeader {
        margin-left: 5px !important;
    }

    .worksheetOuter {
        padding-left: 15px !important;
    }

    .flightPad {
        margin: 3px 0 3px 3px !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .tailsDiv {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        flex-direction: unset;
        justify-content: space-around;
        overflow-y: auto;
    }

    .hideInMax {
        display: block;
    }

    .showMx {
        display: flex;
    }

    .showInMax {
        display: none;
    }

    .showIP {
        display: block;
    }

    .hideIP {
        display: none;
    }

    .showIPFlex {
        display: flex;
    }

    .hideIPFlex {
        display: none;
    }

    .detailContent {
        margin-left: 10px;
        border: none;
        padding-left: 10px;
        flex: 1;
    }

    .otherSelected {
        overflow: auto;
        flex: 1;
        /*    margin-top: -10px;*/
        border: solid 2px #eff1f5;
        border-radius: 12px;
        padding: 10px;
        overflow-x: hidden;
    }

    .statusPad-auto {
        text-align: center !important;
        padding: 7px 16px;
        max-width: 125px !important ;
        height: 36px;        
    }
    .statusPad-tab {
        padding: 8px 15px !important;
        max-width: 125px !important;
        height: 36px !important;
        width:100%!important;
    }
    .searchBox {
        height: 48px;
        margin: 0 0 0 0;
        margin-right: 5%;
        margin-left: 5%;
        padding: 0;
        border-radius: 5px;
        border: solid 1px #eff1f5;
        background-color: #ffffff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.5px;
        text-align: left;
        color: #636569;
    }

    .button-width {
        margin: 0px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 18%;
        padding-right: 18%;
        width: 170px !important;
    }

    .noPadding {
        padding: 15px !important;
    }

    .noRightPadding {
        padding-right: 15px !important;
    }
    .zeroRightPadding {
        padding-right: 0px !important;
    }
    .zeroLeftPadding {
        padding-left: 0px !important;
    }

    .noLeftPadding {
        padding-left: 15px !important;
    }

    .colUser {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .emailPadding {
        padding: 0px !important;
    }

    .paddingTopUserDetails {
        padding-top: 0px !important
    }

    .flightDivs {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        margin-bottom: 30px !important;
    }

    .informationModal {
        width: 825px !important;
        height: 934px !important;
        margin-left: 130px !important;
        margin-top: 50px !important;
        border-radius: 8px !important;
        background-color: #ffffff !important;
    }

    .information-modal-content {
        width: 825px !important;
        height: 934px !important;
        border-radius: 8px !important;
        background-color: #ffffff !important;
    }


    .search-container {
        width: 80% !important;
    }

    .min-width-150 {
        min-width: 150px !important;
        max-width: 150px !important;
    }

    .information-top {
        top: 120px !important;
    }

    .lbl-tailnumber {
        width: 110px !important;
        position: relative;
        top: -29px;
    }

    .lbl-grounddetails {
        width: 130px !important;
    }

    .exGTInstance {
        flex-basis: 49%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        /*height: 70px;*/
    }

    .width-280 {
        width: 280px !important;
    }

    .exWorkloadInstance {
        flex-basis: 33%;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between;*/
        cursor: pointer;
        flex-grow: 1;
    }

    .workLoadInstance {
        max-width: 335px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 180px;
        cursor: pointer;
    }

    .status-inactive {
        width: 80px;
        height: 23px;
        display: flex;
        flex-direction: column;
        padding: 4px 16px;
        border-radius: 38px;
        background-color: #eff0f6;
        font-family: Poppins;
        font-size: 10px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.5px;
        text-align: center;
        color: #616472;
    }
    .underConstruction {
        float: left !important;
        display: block;
        font-size: 20px;
        font-weight: bold;
    }

    .flight-status {
        width: 62px;
        height: 23px;
        display: flex;
        flex-direction: column;
        border-radius: 38px;
        background-color: rgba(13, 186, 97, 0.1);
        justify-content: flex-start;
        align-items: center;
        padding: 4px 16px;
        border-radius: 38px;
        background-color: rgba(13, 186, 97, 0.1);
        align-self: center;
        flex-grow: 0;
        font-family: Poppins;
        font-size: 10px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.5px;
        text-align: center;
        color: #0dba61;
    }

    .worksheet-button-width {
        width: 125px !important;
        padding: 8px 15px !important;
        height: 34px !important;
    }

    .blockout-Status {
        margin-right: -25px !important;
    }

    .zero-left-right-padding {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .input-group-search-filter-margin {
        margin-left: 0% !important;
    }

    .width-workload-tab {
        width: 650px !important;
    }

    .width-search-workload {
        width: 450px !important;
    }

    .text-align-right {
        text-align: right !important;
        margin-right: 8% !important;
    }

    .max-width-105 {
        max-width: 105px !important;
    }

    .app > .collapsed + .mainWAuth > .container-fluid > .margin-5-child {
        margin-left: 5% !important;
    }

    .workload-section-text-align-right {
        text-align: right !important;
        margin-right: 35px !important;
    }

    .width-workload-header-100 {
        min-width: 100px !important;
        max-width: 100px !important;
        width: 100px !important;
    }

    .hide-in-big-screen {
        display: none !important;
    }
    .show-in-tablet {
        display: block !important;
    }

    .hide-from-tablet {
        display: none !important;
    }

    .subHeading-wip-list {
        font-family: Poppins !important;
        font-size: 13px !important;
        font-weight: bold !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.8 !important;
        letter-spacing: 0.5px !important;
        text-align: left !important;
        color: #121212 !important;
    }

    .subheading-wip-data {
        font-family: Poppins !important;
        font-size: 12px !important;
        font-weight: normal !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.5 !important;
        letter-spacing: 0.5px !important;
        text-align: left !important;
        color: #636569 !important;
    }

    .subheading-wip-data-status {
        font-size: 12px !important;
    }

    .planned-status-padding {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .text-right-align {
        text-align: right !important;
    }

    .workloadpopOver-filter > .popover {
        will-change: transform !important;
        transform: translate3d(645px, 29px, 0px) !important;
    }

    .departure-notes-div {
        max-width: 380px !important;
    }

    .worksheet-button-full-width {
        width: 87% !important;
        margin-left: 2% !important;
        margin-right: 5% !important;
        height: 34px !important;
    }
    .float {
        position: fixed;
        width: 40px;
        height: 40px;
        bottom: 90px;
        background-color: #f47d30;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
        cursor: pointer;
    }
    .container-fluid {
        max-width: 852px;
    }

    .timeandlabor-label {
        font-family: Poppins !important;
        font-size: 20px !important;
        font-weight: bold !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 30px !important;
        letter-spacing: 1px !important;
        text-align: left !important;
        color: #121212 !important;
        padding-top: 5px !important;
    }

    .timeandlabor-sublabel {
        font-family: Poppins !important;
        font-size: 16px !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 28.8px !important;
        letter-spacing: 0.5px !important;
        color: #919195 !important;
    }

    .my-shift-Box {
        height: 314px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;
        padding: 20px 8px !important;
        border-radius: 8px;
        border: solid 1px #eff1f5;
        padding-right: 20px;
    }
    .rc-time-picker-input{
        width:95% !important
    }
    .my-tasks {
        padding: 16px 10px 16px !important;
    }

    .tablet-employee-profile {
        /*width: 674px;*/
        align-self: stretch;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        /*gap: 24px;*/
        padding: 20px 10px;
        border-radius: 8px;
        border: solid 1px #eff1f5;
        height:100%;
    }

        .tablet-employee-profile .name {
            /*width: 150px;
            height: 29px;*/
            flex-grow: 0;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.8;
            letter-spacing: 0.5px;
            text-align: left;
            color: #121212;
            margin-top: 8px;
        }

        .tablet-employee-profile .text {
            width: 150px;
            height: 29px;
            flex-grow: 0;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.8;
            letter-spacing: 0.5px;
            text-align: left;
            color: #636569;
            margin-left: -35px;
            margin-top: 8px;
        }

        .tablet-employee-profile .row {
            width: 100% !important;
        }

    .tablet-summary-title {
        margin: 0px;
        width: 114.2px;
        height: 30px;
        flex-grow: 0;
        margin: 0 316.8px 20px 0;
        font-family: Poppins;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1px;
        text-align: left;
        color: #121212;
    }

    .timesheet-edit-button {
        width: 100% !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .report-outer {
        height: 200px !important;
    }

    .statusPad4 {
        padding: 10px 0;
        border-radius: 38px;
        background-color: #dfe7f2;
        font-family: Poppins;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 1px;
        text-align: left;
        color: #2F80ED;
        display: inline-block;
    }

    .statusPadPs {
        padding: 10px 0;
        border-radius: 38px;
        background-color: rgba(13, 186, 97, 0.1);
        font-family: Poppins;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.5px;
        text-align: left;
        color: #0dba61;
        display: inline-block;
    }

    .statusPad2 {
        padding: 10px 0;
        border-radius: 38px;
        background-color: rgba(255, 0, 0, 0.1);
        font-family: Poppins;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 1px;
        text-align: left;
        color: #e10412;
        display: inline-block;
    }

    .statusPad-auto {
        text-align: center !important;
        width: 100px;
        height: 38px;
    }

    .padding-x-zero-tablet{
        padding-left:0px;
        padding-right:0px;
    }
}
@media only screen and (max-width: 600px) {
    .workload-round-empty-button-popup {
        cursor: pointer;
        padding: 10px 10px !important;
        font-size: 14px;
    }

    .round-empty-button-popup {
        cursor: pointer;
        padding: 10px 39px;
        font-size: 14px;
    }

    .round-fill-button-popup {
        cursor: pointer;
        padding: 12px 15px !important;
        font-size: 14px !important
    }
    .round-fill-button-popup-large {
        cursor: pointer;
        padding: 12px 15px !important;
        font-size: 14px !important
    }

    .flex-button {
        justify-content: center;
    }

    .smallModal {
        max-width: 100% !important;
    }

        .smallModal .modal-content {
            min-width: 100% !important;
            padding-bottom: 10px;
        }

    .modal-content {
        min-width: 100px !important;
    }

    .flCol {
        border: 0 none;
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .mobileLeftTitle {
        font-family: Poppins;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.5px;
        text-align: left;
        color: #121212;
    }

    .outerDivMxR {
        padding-right: 20px !important;
    }

    .workSheetColumn {
        font-size: 14px !important;
    }

        .workSheetColumn > div > span {
            width: 90px !important;
        }

    .mobile0 {
        margin: 0px !important;
        padding: 1px !important;
    }

    .worksheetTitle {
        font-size: 16px !important;
        padding-top: 10px !important;
    }

    .worksheetOuter {
        padding-left: 2px !important;
        padding-right: 2px !important;
        margin: 0px !important;
    }

    .dimTitle {
        font-size: 16px !important;
    }

    .workSheetRow {
        display: block !important;
    }

    .lighterText {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.5px;
        text-align: justify;
    }

    h1 {
        font-family: Poppins;
        font-weight: bold;
        padding-bottom: 10px;
        font-size: 24px;
        text-align: center;
    }

    .signIn {
        margin: 0px;
        padding-left: 20px !important;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1px;
        text-align: left;
        color: #fff !important;
        border-bottom: solid 1px gray;
    }

    .signInST {
        margin: 0px;
        padding-left: 20px !important;
        font-family: Poppins;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1px;
        text-align: left;
        color: #25313e !important;
    }

    .navbar {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .nav-list {
        background-color: #25313e;
        color: white;
    }

    .text-dark {
        color: #fff !important;
        font-size: 16px;
        font-family: Poppins;
        font-weight: bold;
        line-height: 1.8;
        letter-spacing: 0.5px;
    }


    .text-dark-white {
        color: #fff !important;
        font-size: 16px;
        font-family: Poppins;
        font-weight: bold;
        line-height: 1.8;
        letter-spacing: 0.5px;
    }



    .nav-list-item > a:hover {
        color: #f47d30 !important;
        font-size: 16px;
    }

    .dropdownNav > a:hover {
        color: #f47d30 !important;
        font-size: 16px;
    }


    .text-dark:focus {
        color: #f47d30 !important;
        font-size: 16px;
    }

    .nav-list-item {
        border-bottom: solid 1px gray;
        color: white !important;
        padding-top: 15px;
    }

    .mainWAuth {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        background-color: #fff !important;
        margin: 0 !important;
        border: none;
        border-radius: 0px;
    }


    .navbarBottom {
        border-bottom: solid 1px gray;
        background-color: #fff !important;
    }

    .navbarLogo {
        height: 28px;
        padding-left: 5px;
    }

    .navbarLogoPM {
        height: 22px;
        padding-left: 5px;
    }

    .smhidden {
        display: none;
    }

    .smdisplay {
        display: block;
        padding-left: 20px;
    }

    .smdisplaynp {
        display: block;
        padding-left: 0px;
    }

    .horStack {
        display: block;
    }


        .horStack > div {
        }

    .padding5 {
        padding: 48px 0px 48px 0px !important;
    }

    .divBtn {
        border: solid 2px #f47d30;
        padding: 4px;
        cursor: pointer;
        border-radius: 5px;
        text-align: center;
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 1px;
        text-align: center;
        color: #5e5e5e;
    }

    .h4 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1px;
        text-align: center;
        color: #636569;
    }

    .h1 {
        font-family: Poppins;
        font-weight: bold;
        font-size: 24px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1px;
        text-align: center;
        color: #121212;
    }

    .underConstruction {
        float: left !important;
        display: block;
        font-size: 20px;
    }

    .searchBox {
        height: 48px;
        margin: 0 0 0 0;
        margin-right: 5%;
        margin-left: 5%;
        padding: 0;
        border-radius: 5px;
        border: solid 1px #eff1f5;
        background-color: #ffffff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.5px;
        text-align: left;
        color: #636569;
    }

    .colorLegendModal {
        width: 350px !important;
        height: 350px !important;
        margin-top: 100px !important;
        margin-right: 22px !important;
        border-radius: 8px !important;
        background-color: #ffffff !important;
        float: right !important;
    }

    .colors-modal-content {
        width: 350px !important;
        height: 350px !important;
        border-radius: 8px !important;
        background-color: #ffffff !important;
        min-width: 315px !important;
    }

    .informationModal {
        width: 300px !important;
        /*height: 934px !important;*/
        margin-top: 10px !important;
        margin-left: 14px !important;
        border-radius: 8px !important;
        background-color: #ffffff !important;
    }

    .information-modal-content {
        width: 300px !important;
        /* height: 934px !important;*/
        border-radius: 8px !important;
        background-color: #ffffff !important;
        min-width: 360px !important;
    }

    .Flightboard-Boad-Update {
        height: 30px;
        width: 331px !important;
        margin: 10px 191px 10px -20px;
        font-family: Poppins;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1px;
        text-align: center;
        color: #121212;
    }

    .information-title {
        height: 44px;
        margin: 5px 0 0;
        font-family: Poppins;
        font-size: 12px;
        font-weight: bold !important;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.5px;
        text-align: left;
        color: #121212;
    }

    .information-heading {
        height: 29px;
        margin: 0 0.3px 30px 0px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.5px;
        text-align: center;
        color: #121212
    }

    .text-align-left {
        text-align: left !important;
    }

    .information-legend-labels {
        /*height: 22px;*/
        margin: 1px 0 1px 23.8px;
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.5px;
        text-align: left;
        color: #636569;
    }

    .information-legend-sub-labels {
        margin: 16px 0 0;
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.5px;
        text-align: center;
        color: #636569;
    }

    .margin-left-minus {
        margin-left: -15px !important;
    }

    .info-section {
        height: 215px;
    }

    .bottom-margin {
        margin-bottom: 5px !important;
    }

    .zero-left-margin {
        margin-left: 0px !important;
    }

    /*.popover {
        max-width: 350px !important;
        height: 500px !important;
    }*/

    .zero-left-right-padding {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }


    .mainDarkModeTheme {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        margin: 0 !important;
        border: none;
        border-radius: 0px;
    }

    .img-customer-logo {
        width: 56.6px;
        height: 16px;
    }

    .customer-Logo-tail-div {
        width: 68px;
        height: 40px;
        margin: 1px 2px 0 0px;
        padding: 5.5px 6.5px 5.5px 0;
    }

    .customer-logo-div-width {
        width: 60px;
    }

    .ground-time-data {
        width: 100px;
    }

    .ground-time-height {
        height: 65px !important;
    }

    .time-station-div {
        width: 50px;
        height: 40px;
        /*margin: 1px 11.5px 0 11px;*/
        padding: 5.5px 6.5px 5.5px 0;
    }

    .status-div {
        width: 33px;
        height: 40px;
        /*margin: 56px 11px 0 45px;*/
        padding: 5.5px 6.5px 5.5px 0;
    }

    .departure-station-detail-div {
        width: 107px;
        height: 42px;
        padding: 5.5px 6.5px 5.5px 0;
    }

    .departure-notes-div {
        flex-grow: 0;
        max-width: 300px !important;
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.5px;
        text-align: left;
        color: #919195;
    }

    /*.div-data-row {
        width: 350px;
        height: 40px;
    }*/

    .left-padding {
        padding-left: 0px !important;
    }

    .CVG-label {
        width: 68.6px;
        height: 24px;
        margin: 0 95.2px 0 5px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.5px;
        text-align: left;
        color: #ffffff;
    }

    .max-width-50 {
        max-width: 50% !important;
    }

    .min-width-150 {
        min-width: 150px !important;
        max-width: 150px !important;
    }

    .margin-IO-75 {
        margin-right: 75px;
    }

    .extended-groundtime-div {
        display: flex;
        flex-wrap: wrap;
        max-width: 360px;
        flex-direction: column;
    }

    .exGTInstance {
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        /*height: 60px;*/
    }

    .lbl-tailnumber {
        width: 90px !important;
        position: relative;
        top: -29px;
    }

    .lbl-grounddetails {
        width: 100px !important;
    }

    .information-top {
        top: 0px !important;
    }

    .margin-bottom-info-subheadings {
        margin-bottom: 15px !important;
    }

    .left-padding-10 {
        padding-left: 10px !important;
    }

    .exWorkloadInstance {
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        flex-grow: 2;
    }

    .rc-pagination-item {
        display: none !important;
    }

    .rc-pagination-jump-next button::after, .rc-pagination-jump-prev button::after {
        display: none !important;
    }

    .rc-pagination-jump-prev {
        display: none !important;
    }

    .rc-pagination-jump-next {
        display: none !important;
    }


    .workload-details-title {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.5px;
        text-align: left;
        color: #636569;
    }

    .blockout-Status {
        width: 98.2px;
        height: 22px;
        flex-grow: 0;
        margin: 6px 57.7px 6px 0;
        font-family: Poppins;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.5px;
        text-align: left;
        color: #919195;
    }

    .content-margin-left {
        margin-left: 7px !important;
    }

    .worksheet-section-height {
        min-height: 330px !important;
        height: 330px !important;
    }

    .worksheet-cancel-section-height {
        min-height: 290px !important;
        height: 290px !important;
    }

    .margin-bottom-6 {
        margin-bottom: 6px !important;
    }

    .margin-bottom-3 {
        margin-bottom: 3px !important;
    }

    .rc-pagination-prev button {
        width: 170px !important;
        margin: 0 0 0 0 !important;
    }

    .rc-pagination-next button {
        width: 130px !important;
    }

    .workLoadInstance {
        max-width: 360px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 180px;
        cursor: pointer;
    }

    .margin-left-40 {
        margin-left: 40px !important;
    }

    .hideFilterBy {
        display: none !important;
    }

    .width-search-workload {
        width: 210px !important;
    }

    .input-group-search-filter-margin {
        margin-left: 3% !important;
    }

    .app > .collapsed + .mainWAuth > .container-fluid > .margin-5-child {
        margin-left: -15px !important;
    }

    .rc-pagination-jump-next {
        display: none !important;
    }

    .workload-section-text-align-right {
        text-align: right !important;
        margin-right: 10px !important;
    }

    .width-workload-header-100 {
        min-width: 110px !important;
        max-width: 110px !important;
        width: 110px !important;
    }

    .rc-pagination-prev button:after {
        padding-left: 17px !important;
    }

    .show-in-mobile {
        display: block !important;
    }

    .hide-from-mobile {
        display: none !important;
    }

    .width-120-mx {
        width: 120px !important;
        padding-left: 10px !important;
    }

    .width-180-mx {
        width: 180px !important;
        padding-right: 0px !important;
        margin-right: 0% !important;
    }

    .width-300-mx {
        width: 300px !important;
        padding-left: 10px !important;
    }

    .outerDivMxWip {
        padding: 25px 10px 41px 10px;
        border-radius: 12px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 1px 0 rgba(0, 0, 0, 0.07), 0 1px 1px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px #eff1f5;
        margin-bottom: 20px;
    }

    .inner-div-WIP {
        padding: 9px 3px 9px 3px !important;
        border: solid 0.5px #eff1f5 !important;
        background-color: #ffffff !important;
    }

    .subHeading-wip-list {
        font-family: Poppins !important;
        font-size: 12px !important;
        font-weight: bold !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.8 !important;
        letter-spacing: 0.5px !important;
        text-align: left !important;
        color: #121212 !important;
    }

    .subheading-wip-data {
        font-family: Poppins !important;
        font-size: 10px !important;
        font-weight: normal !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.8 !important;
        letter-spacing: 0.5px !important;
        color: #636569 !important;
    }

    .heading-wip {
        font-family: Poppins !important;
        font-size: 14px !important;
        font-weight: bold !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.8 !important;
        letter-spacing: 0.5px !important;
        text-align: left !important;
        color: #121212 !important;
    }

    .planned-status-padding {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .mx-Mobile-Modal {
        top: 65px !important;
        min-width: 350px !important;
    }

        .mx-Mobile-Modal > .modal-content {
            padding-left: 5px !important;
            min-width: 350px !important;
        }

    .mxrequest-save-div {
        min-width: 340px !important;
    }

    .mxrequest-form-div {
        min-width: 340px !important;
    }

    .mxrequest-save-button-div {
        position: fixed !important;
        bottom: 0% !important;
        left: 0% !important;
    }

    .header-title-popup {
        flex-grow: 0;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.5px;
        text-align: left;
        color: #121212;
    }

    .div-logs-worksheet {
        max-height: 100px !important;
    }

    .text-right-align {
        text-align: right !important;
    }

    .cancel-popover-button {
        padding: 9px 20px;
        height: 42px;
        font-family: Poppins;
        font-size: 14px !important;
    }

    .save-popover-button {
        width: 165.7px !important;
        height: 42px;
        margin: 0 0 0 12.1px !important;
        padding: 12px 14.3px !important;
        border-radius: 24px;
        background-color: #f47d30;
        font-family: Poppins;
        font-size: 14px !important;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: 1px;
        text-align: left;
        color: #ffffff;
    }

    .workloadpopOver-filter > .popover {
        will-change: transform !important;
        transform: translate3d(20px, 5px, 0px) !important;
    }

    .save-button-width-add {
        width: 100px !important;
        padding-left: 29px !important;
        margin-left: 20px !important;
    }

    .plan-title-mobile {
        min-width: 220px !important;
    }

    .bs-popover-auto {
        top: 60px !important;
    }

    .worksheet-button-full-width {
        width: 90% !important;
        margin-left: 2% !important;
        margin-right: 2% !important;
    }

    .modal-footer {
        padding: .25rem !important;
    }

    .statusPad-Rescheduled {
        padding: 4px 12px;
        border-radius: 38px;
        background-color: #dfe7f2;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 21.6px;
        letter-spacing: 0.5px;
        text-align: left;
        color: #2F80ED;
        display: inline-block;
    }

    .statusPad-Planned {
        padding: 4px 12px;
        border-radius: 38px;
        background-color: rgba(13, 186, 97, 0.1);
        font-family: Poppins;
        font-size: 12px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 21.6px;
        letter-spacing: 0.5px;
        text-align: left;
        color: #0dba61;
        display: inline-block;
    }

    .statusPad-Cancelled {
        padding: 4px 12px;
        border-radius: 38px;
        background-color: rgba(255, 0, 0, 0.1);
        font-family: Poppins;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 21.6px;
        letter-spacing: 0.5px;
        text-align: left;
        color: #e10412;
        display: inline-block;
    }

    .statusPad {
        height: 30px;
    }

}

.ag-row-hover {
    background: rgba(244, 125, 49, 0.15) !important;
    color: black !important;
}

.ag-column-hover {
}

.ag-cell-value {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 1px;
    text-align: left;
    color: #636569;
}

.ag-header-cell-text {
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 1px;
    text-align: left;
    color: #121212;
}

.ag-header-cell {
    box-shadow: inset 0 -1px 0 0 rgba(102, 102, 102, 0.08) !important;
    background-color: #fff !important;
    border: none !important;
}
 
    .ag-header-row {
    border: none !important;
    outline: none !important;
}

.ag-header {
    border: none !important;
    outline: none !important;
}

.ag-row {
    border: none !important;
    outline: none !important;
}

.ag-cell {
    box-shadow: inset 0 -1px 0 0 rgba(102, 102, 102, 0.08) !important;
    padding-top: 10px !important;
}

.accountDetails {
    width: 100%;
}

.userProfileButton {
    border-radius: 24px;
    background-color: #f47d30;
    padding: 8px 20px;
    border-radius: 24px;
    border: solid 2px #f47d30;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-right: 50px !important;
}
    .userProfileButton:before {       
        background: url('add user.png');
        content: '';
        display: block;
        height: 25px;  
        width: 25px;  
        margin-right:-12px;
    }
.userProfileButton:hover {
    background-color: #CF3C00;
}
    .userProfileButton > div > svg {
        height: 15px;
    }

.add-user {
    margin: 3px 0 3px 20px;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 1px;
    text-align: left;
    color: #ffffff;
}

.vector {
    width: 1em !important;
    height: 1.5em !important;
    border: solid 2px #f47d30;
    margin-left: 0px !important;
}

.accountDetailsLabel {
    margin-bottom: 0px !important;
    width: 200px;
    height: 24px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #121212;
}

.ag-root {
    border: 0px !important;
}

.search-container {
    height: 46px !important;
    width: 90%;
    background-color: #ffffff;
    border: 1px solid #eff1f5;
    padding-left: 10px;
    border-radius: 5px;
}

    .search-container:focus {
        outline: none !important;
    }

.input-group-text-search-container:focus {
    outline: none !important;
}

.filterBox {
    width: 100% !important;
    display: flex;
    justify-content: left;
    padding: 8px 5px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
}

    .filterBox:focus {
        border: 2px;
        outline-color: #f47d30;
    }

.filterDropdownWidth {
    width: 100%;
    height: 48px;
}

.Filter-by {
    width: 90%;
    height: 46px;
    margin: 0 0 0 10px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #636569;
}

.gridText {
    font-family: Poppins !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.8 !important;
    letter-spacing: 1px !important;
    text-align: left !important;
    color: #636569 !important;
}

    .gridText > span > a {
        font-family: Poppins !important;
        font-size: 16px !important;
        font-weight: normal !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.8 !important;
        letter-spacing: 1px !important;
        text-align: left !important;
        color: #636569 !important;
    }


.btnFilter {
    color: #636569 !important;
    border-color: #eff1f5 !important;
}

    .btnFilter:hover {
        color: #f47d30 !important;
        /*box-shadow: 0 0 0 0.2rem rgba(244, 125, 48, 0.75) !important;*/
        border: solid 2px #f47d30 !important;
    }

        .btnFilter:hover > span {
            color: #636569 !important;
        }

    .btnFilter:focus {
        /*box-shadow: 0 0 0 0.2rem rgba(244, 125, 48, 0.75) !important;*/
        border: solid 2px #f47d30 !important;
    }

.filterColor {
    color: #636569 !important;
    border-color: #eff1f5 !important;
}

    .filterColor:hover {
        color: #f47d30 !important;
    }

    .filterColor:focus {
        color: #f47d30 !important;
    }

.selectUser {
    width: 100% !important;
}

.floatRight {
    float: right !important;
    text-align: right !important;
}

.popOver-style {
    width: 306.5px;
    height: 111px;
    margin: 9.2px 751.1px 761.5px 4.1px;
    padding: 19px 16.3px 2px;
    border-radius: 8px;
    background-color: #ffffff;
}

.popover-header {
    background-color: #ffffff !important;
    border-bottom: 0px !important;
    height: 25px !important;
    font-family: Poppins !important;
    font-size: 14px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.8 !important;
    letter-spacing: 0.5px !important;
    text-align: left !important;
    color: #121212 !important;
}

.leftMargin {
    margin-left: 5px;
}

.width-Fa-Left {
    width: 20px !important;
    height: 18px !important;
    margin-bottom: 6px !important;
    margin-right: 10px !important;
    cursor: pointer !important;
}

.userTitle {
    font-family: Poppins !important;
    font-size: 24px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: 1px !important;
    text-align: left !important;
    color: #121212 !important;
}

.custom-switch {
    padding-left: 2.25rem !important;
    padding-bottom: 1rem !important;
}

.custom-control-label {
    padding-left: 2rem !important;
    padding-bottom: 0.1rem !important;  
}

.custom-switch .custom-control-label::before {
    left: -2.25rem !important;
    height: 1.5rem !important;
    width: 3.1rem !important;
    border-radius: 1rem !important;
    background-color: #d6d8e7 !important;
    /*  border: 3px solid red !important; */
    /*box-shadow: 0 0 10px red;*/
}
 
    .custom-switch .custom-control-label::before:focus-visible {
        border: 3px solid red !important;
    }

    .custom-switch .custom-control-label::after {
        top: calc(0.25rem + 2px) !important;
        left: calc(-2.25rem + 2px) !important;
        width: calc(1.5rem - 4px) !important;
        height: calc(1.5rem - 4px) !important;
        border-radius: 2rem !important;
        background-color: white !important;
    }

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff !important;
    -webkit-transform: translateX(1.5rem) !important;
    transform: translateX(1.5rem) !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
    background-color: #f47d30 !important;
    border: #f47d30 !important;
}
/*switch box border change on focus blue to #f47d30*/
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none !important;
    border: 1px solid #f47d30 !important;
}

.cancel-popover-button {
    padding: 9px 20px;
    height: 42px;
}

.rss.opacity {
    filter: invert(0%) sepia(100%) saturate(22%) hue-rotate(29deg) brightness(93%) contrast(0%);
    padding-bottom: 10px !important;
}

.time-input {
    position: relative;
    width: 240px;
}

    .time-input input {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding-left: 40px;
        border-radius: 5px;
        height: 41px !important;
    }

    .time-input img {
        position: absolute;
        top: 8px;
        left: 30px;
        bottom: 5px;
    }


.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.filter-btn-height {
    height: 46px !important;
}


.tooltip-flighBoard {
    width: 112.1px;
    height: 33.4px;
    margin: 26.7px 195px 1.4px 220.9px;
    padding: 2px 4px;
    border-radius: 8px;
    background-color: #fafafa;
}

.tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 1px solid #062e56;
}

.left-padding-negative {
    padding-left: -15px !important;
    margin-left: -15px !important;
    right: 0px !important;
    padding-right: 0px !important;
}

.tooltip-inner {
    background-color: #fff !important;
    color: #000 !important;
    border: 0px solid #062e56 !important;
    border-radius: 10px !important;
}

.tooltip-top .arrow::before {
    border: 0px;
    border-bottom: 5px solid #fff;
    border-top: 5px solid #fff;
    border-top-color: #fff !important;
    border-bottom-color: #fff !important;
    border-top-color: #fff !important;
    color: #fff !important;
}

.tooltip-top .arrow::after {
    border: 0px;
    border-bottom: 5px solid #fff;
    border-top: 5px solid #fff;
    border-top-color: #fff !important;
    border-bottom-color: #fff !important;
    border-top-color: #fff !important;
    color: #fff !important;
}

.tooltip-text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    color: #121212;
}


.filter-close-style {
    padding-right: 20px;
    padding-top: 20px;
}

.filter-crossButton {
    font-size: 20px;
    height: 20px;
    color: #616472;
    cursor: pointer;
}

.center-alignment {
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-right-padding-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
}

.asterisk:after {
    content: " *";
    color: red;
}

.timeandlabor-label {
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 36px;
    letter-spacing: 1px;
    text-align: left;
    color: #121212;
    padding-top: 5px !important;
}

.timeandlabor-sublabel {
    font-family: Poppins;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 28.8px;
    letter-spacing: 0.5px;
    color: #919195;
}

.submit-approval-button {
    padding: 8px 0px;
    border-radius: 24px;
    border: solid 2px #f47d30;
    background-color: #f47d30;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.my-shift-title {
    width: 114.2px;
    height: 30px;
    flex-grow: 0;
    margin: 0 316.8px 12px 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: #121212;
}

.my-shift-clock-label {
    width: 90px;
    height: 29px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #121212;
}

.my-shift-Box {
    /*height: 272px;*/
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    padding: 20px 40px;
    border-radius: 8px;
    border: solid 1px #eff1f5;
    padding-right: 20px;
}

.employee-profile {
    /*width: 674px;*/
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    padding: 36px 40px;
    border-radius: 8px;
    border: solid 1px #eff1f5;
}

.summary-title {
    height: 30px;
    flex-grow: 1;
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: #121212;
}

.employee-profile .name {
    /*width: 56px;*/
    height: 29px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #121212;
}

.employee-profile .text {
    /*width: 56px;*/
    height: 29px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
    text-align: left;
    color: #636569;
}

.employee-profile .row {
    width: 100% !important;
}

.my-tasks {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    padding: 16px 40px 32px;
    border-radius: 8px;
    border: solid 1px #eff1f5;
}

    .my-tasks .row {
        width: 100% !important;
    }

    .my-tasks .name {
        /*width: 56px;*/
        height: 29px;
        flex-grow: 0;
        font-family: Poppins;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0.5px;
        text-align: left;
        color: #121212;
    }

.trash-icon {
    color: #e10412
}

.padding-bottom-36 {
    padding-bottom: 36px;
}
.padding-bottom-26 {
    padding-bottom: 26px;
}

.comment-icon {
    color: #636569;
}

.clock-in-time{
    margin-left: 30px;
    /*width: 25%;*/
    height: 38px !important;
}

.clock-in-time {
    height: 38px !important;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #eff1f5;
    padding-left: 10px;
    border-radius: 5px;
    color: #919195;
}

    .clock-in-time:focus {
        outline: none !important;
    }

.react-timekeeper {
    position: absolute !important;
    z-index: 1;
}

.diff-hours-ok {
    width: 100%;
    text-align: center;
}

.diff-hours-modal .modal-content {
    width: 80% !important;
    min-width: 286px !important;
}

.status-submitted {
    width: 157px;
    height: 34px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    padding: 8px 12px;
    border-radius: 38px;
    background-color: #fff0d2;
    margin-left: 20px !important;
    margin-top: 5px !important;
}

.submitted-status-text {
    width: 99px;
    height: 18px;
    align-self: center;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 1px;
    text-align: left;
    color: #fdb825;
}

.status-locked {
    width: 338px;
    height: 32px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    padding: 8px 12px;
    border-radius: 38px;
    background-color: rgba(255, 0, 0, 0.1);
    margin-left: 20px !important;
    margin-top: 5px !important;
}

.locked-status-text {
    width: 265px;
    height: 18px;
    align-self: center;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 1px;
    text-align: left;
    color: #e10412;
}

.status-approved {
    width: 157px;
    height: 34px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    padding: 8px 12px;
    border-radius: 38px;
    background-color: #e7f8ef;
    margin-left: 20px !important;
    margin-top: 5px !important;
}

.approved-status-text {
    width: 94px;
    height: 18px;
    align-self: center;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 1px;
    text-align: left;
    color: #0dba61;
}

.ot-time {
    background-color: #ffffff;
    color: #919195;
}

.calender-disable {
    background-color: #e9ecef
}

.timesheet-edit-button{
    width: 30%;
    float: right;
    text-align: center;
}

.team-timesheet-label {
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: #121212;
}

.add-new-timesheet {
    border-radius: 24px;
    padding: 8px 10px;
    border: solid 2px #f47d30;
    background-color: #f47d30;
    cursor: pointer;
    float: right;
}


.add-user {
    margin: 3px 0 3px 20px;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 1px;
    text-align: left;
    color: #ffffff;
}

.timesheet-approve-style {
    width: 192px !important;
    height: 48px !important;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 12px 42px;
    object-fit: contain;
    border-radius: 24px;
}

.timesheet-export-style {
    width: 180px !important;
    height: 48px !important;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 12px 42px;
    object-fit: contain;
    border-radius: 24px;
    margin-left:10px;
}

.addnewtimesheet-style {
    width: 209px !important;
    height: 48px !important;
    flex-grow: 0;
    display: flex;
    /* flex-direction: column;*/
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 12px 42px;
    object-fit: contain;
    border-radius: 24px;
    padding: 9px 25px;
    float: right;
    vertical-align: middle;
}

.search-container-approval {
    height: 46px !important;
    background-color: #ffffff;
    border: 1px solid #eff1f5;
    padding-left: 10px;
    border-radius: 5px;
}
.margin-right-minus-30 {
    margin-right: -10px;
}
.approval-input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100% !important;
}

.approvals-approve-button {
    border-radius: 24px;
    padding: 9px 20px;
    border-radius: 24px;
    border: solid 2px #f47d30;
    background-color: #f47d30;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.approvals-approve-button:hover {
    background-color: #CF3C00;
}
.approvals-approve-button-disable {
    background-color: #ffd0bc;
    border: 2px solid #ffd0bc;
    pointer-events:none;

}   

.approvals-export-button {
    cursor: pointer;
    padding: 9px 39px;
    border-radius: 24px;
    border: solid 2px #f47d30;
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 1px;
    text-align: left;
    color: #f47d30;
    background-color: #fff;
    border-radius: 24px;
    padding: 8px 20px;
    border: solid 2px #f47d30;
    line-height: 1.5;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

    .approvals-export-button:hover {
        background-color: #f47d30;
        color: #fff;
    }

.approvals-export-button-disable {
    background-color: #ffffff;
    color: #ffd0bc;
    border: 2px solid #ffd0bc;
    pointer-events:none;
}

.approvals-button-width {
    margin: 0px;
    padding-top: 9px;
    padding-bottom: 0;
    padding-left: 22px;
    width: 192px !important;
    height: 48px;
    text-align: center;
}

.approvals-export-button-width {
    margin-left:22px;
    padding-top: 9px;
    padding-bottom: 0;
    padding-left: 28px;
    width: 192px !important;
    height: 48px;
}

.approval-export {
    margin: 3px 0 3px 20px;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 1px;
    text-align: left;    
}

.vector-noborder {
    width: 1em !important;
    height: 1.5em !important;
    margin-left: 0px !important;
}

.ag-icon-checkbox-unchecked {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: relative;
    top: 0;
    width: 28px;
    background: none !important;
}
span[class='ag-header-select-all'] span.ag-icon-checkbox-unchecked {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 16px;
    left: 0;
    position: relative;
    top: 0;
    background: none !important;
   
}
span[class='ag-header-select-all'] {   
  /*  pointer-events: none;    */
}

span [class='ag-header-cell-text'] {
    margin-left: 0px;
}

.ag-icon-checkbox-checked {
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: relative;
    top: 0;
    width: 28px;
    background: none !important;
    background-color: #f47d30 !important;
}
span[class='ag-header-select-all'] span.ag-icon-checkbox-checked {
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 16px;
    left: 0;
    position: relative;
    top: 0;
    width: 16px;
    background: none !important;
    background-color: #f47d30 !important;
}


span[class='ag-header-select-all'] span.ag-icon-checkbox-indeterminate {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 16px;
    left: 0;
    position: relative;
    top: 0;
    background: none !important;
}
.approval-grid-status-pending {
    width: 107px;
    height: 39px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    border-radius: 38px;
    margin: -3px 0px 0px -5px;
    background-color: #fff0d2;
}

.approval-grid-status-pending-text {
    width: 75px;
    height: 25px;
    align-self: center;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 1px;
    text-align: center;
    color: #fdb825;
    margin-top: 10px;
}


.approval-grid-status {
    width: 107px;
    height: 39px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    border-radius: 38px;
    margin: -3px 0px 0px -5px;
    background-color: rgba(13, 186, 97, 0.1);
}

.approval-grid-status-text {
    width: 75px;
    height: 25px;
    align-self: center;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 1px;
    text-align: center;
    color: #0dba61;
    margin-top: 10px;
}

.trax-grid-status-process {
    width: 107px;
    height: 39px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    border-radius: 38px;
    margin: -3px 0px 0px -5px;
    background-color: #d0fbe5;
}

.trax-grid-status-process-text {
    width: 75px;
    height: 25px;
    align-self: center;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 1px;
    text-align: center;
    color: #0DBA61;
    margin-top: 10px;
}

.trax-grid-status-failed {
    width: 107px;
    height: 39px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    border-radius: 38px;
    margin: -3px 0px 0px -5px;
    background-color: #ffcccc;
}

.trax-grid-status-failed-text {
    width: 75px;
    height: 25px;
    align-self: center;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 1px;
    text-align: center;
    color: #cc0000;
    margin-top: 10px;
}

.round-empty-button-approval {
    cursor: pointer;
    border-radius: 24px;
    border: solid 1px #f47d30;
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 1px;
    text-align: left;
    color: #f47d30;
    background-color: #fff;
    margin-right: 20px;
}
    .round-empty-button-approval:hover {
        background-color: #f47d30;
        color: #fff;
    }
.approval-dateTime {
        height: 46px;
        padding-top: 9px;
    }
.approval-ag-grid-view-icon {
    background-image: url('/plane-arrival.png');
}

.ag-theme-balham .ag-header-cell {
    background-color: #eff1f5 !important;
}
.ag-theme-balham .ag-header-cell-label
{
    margin-top:3px;
}
.ag-theme-balham .ag-header-select-all {
    margin-top: 3px;
}
.ag-theme-balham .ag-icon-none {
    margin-top: -3px;
    background-image: url('FEAMAugurIcons/sort/grid-sort/sort@3x.png') !important;
}
.ag-theme-balham .ag-icon-asc {
    margin-top: -3px;
    background-image: url('FEAMAugurIcons/sort/grid-sort/sort-ascending@3x.png') !important;
}
.ag-theme-balham .ag-icon-desc {
    margin-top: -3px;
    background-image: url('FEAMAugurIcons/sort/grid-sort/sort-descending@3x.png') !important;
}
.ag-theme-balham .ag-icon-menu{
    display:none !important;
}

.timeAndLabor-cancel-button {
    padding: 8px 20px;
    border-radius: 24px;
    border: solid 2px #f47d30;
    background-color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: #f47d30;
    cursor: pointer;
    display: flex;
    justify-content: center;
    float: right;
    margin-right: 25px;
    width: 146px;
}

.timeAndLabor-approvesheet-button {
    padding: 8px 0px;
    border-radius: 24px;
    border: solid 2px #f47d30;
    background-color: #f47d30;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 48px;
    margin-right: 20px;
    max-width: 300px;
    float:right;
}
    .timeAndLabor-approvesheet-button:hover {
        background-color: #CF3C00;
    }

.timeAndLabor-button-width {
        padding-top: 9px;
        padding-left: 22px;
        height: 48px;
    }
.submit-button-width{
    width:170px;
}

.approve-button-width {
    width: 250px;
}

.timeAndLabor-button-cancel-width {
    padding-top: 9px;
    padding-left: 22px;
    height: 48px;
    max-width: 300px;
}

.timeAndLabor-button-cancel-width:hover {
        background-color: #f47d30;
        color: #fff;
    }
    .approval-ag-grid-header-margin {
        margin-left: 15px;
    }

.btn-width-planning {
    width: 100%;
    margin-left: 16px;
}

.filter-width-planning {    
    width: 175px;
}

.filter-height-planning{
    height:48px;
    padding-top:10px;
}

 
.margin-left-minus {
    margin-left: -11px !important;
}
.margin-left-minus-3{
    margin-left:-3px;
}
.margin-left-16 {
    margin-left: 16px;
}
.margin-left-10 {
    margin-left: 10px;
}
.margin-left-6 {
    margin-left: 6px;
}

.margin-left-24 {
    margin-left: 24px !important;
}

.margin-top-20{
    margin-top:20px;
}
.padding-left-13{
    padding-left:13px !important;
}
.padding-right-16{
    padding-right:16px;
}
.padding-right-40 {
    padding-right: 40px;
}
.border-bottom-line {
    border-bottom: 1px solid #d9d9d9 !important;
}

.workload-btn-padding-tb {
    padding-top: 5px;
    padding-bottom: 5px;
}

.worksheet-step {
    font-size: 12px !important;
    padding-top: 12px;
}

.model-btn-margin {
    margin-left: 24px !important;
}

.pro-icon-wrapper {   
    font-size: 18px !important;   
}
.pro-inner-item{
    /*margin-top:10px !important;*/
}

div[class="pro-sidebar-content"] div[firstchild="1"]{   
    margin-top:5px;
}

.file-uploaded {
    border-radius: 38px;
    background-color: #e9ecef;
    display: inline-block;
    margin-right: 2% !important;
    margin-top: 1% !important;
}

.file-uploaded-icon {
    color: #FFA500;
    margin-left: 5px;
    margin-right: 10px;
}

.file-delete-icon {
    margin-left: 5px;
    margin-right: 5px;
}

@media only screen and (max-width: 1800px) {

    .workload-Container {
        max-width: 1520px !important;
    }

    .planing-Container {
        max-width: 1520px !important;
    }
    .flightBoard-Container {
        max-width: 1520px !important;
    }
    .dashboard-Container {
        max-width: 1520px !important;
    }
    .report-Container {
        max-width: 1600px !important;
    }

}
@media only screen and (min-width: 1800px) and (max-width: 1800px) {
    .text-align-right {
        margin-right: 2.5rem !important;
    }
}

@media only screen and (max-width: 1600px) {

    .workload-Container {
        max-width: 1475px !important;
    }

    .planing-Container {
        max-width: 1475px !important;
    }
    .flightBoard-Container {
        max-width: 1475px !important;
    }
    .report-Container {
        max-width: 1475px !important;
    }
}
   
.dashboard-Container {
        max-width: 1475px !important;
    }


@media only screen and (max-width: 1440px) {

    .workload-Container {
        max-width: 1238px !important;
    }
    .workload-strech-margin {
        flex-basis: 33%;
        margin-right:0px;
    }
    .dashboard-Container {
        max-width: 1238px !important;
    }

    .planing-Container {
        max-width: 1238px !important;
    }
    .flightBoard-Container {
        max-width: 1238px !important;
    }

    .report-Container {
        max-width: 1238px !important;
    }

}

    div[class="rdt"] input[type="text"] {
        padding-left: 37px !important;
        max-width: 228px !important;
    }

    div[class="rdt rdtOpen"] input[class="form-control"]:focus {
        padding-left: 37px !important;
        max-width: 228px !important;
    }

    .rdtPicker td.rdtToday {
        /* border-radius: .3rem !important;
    background-color: #ffd0bc !important;
    color: #f47d30 !important;*/
    }

    .rdtPicker td.rdtActive, .rdtPicker td.rdtActive {
        border-radius: .3rem !important;
        background-color: #ffd0bc !important;
        color: #f47d30 !important;
    }

    .rdtPicker td.rdtToday:before {
        /* display: none !important;*/
        content: "";
        display: inline-block;
        border-left: 7px solid transparent;
        border-bottom: 7px solid #f47d30 !important;
        border-top-color: rgba(0,0,0,.2);
        position: absolute;
        bottom: 4px;
        right: 4px;
    }

    .rdtPicker td.rdtDay:hover {
        border-radius: .3rem !important;
        background-color: #ffd0bc !important;
        color: #f47d30 !important;
    }

    .rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
        border-radius: .3rem !important;
        background-color: #ffd0bc !important;
        color: #f47d30 !important;
    }

    .rdtPicker td.rdtToday {
        position: unset;
    }

    .rdtPicker th.rdtSwitch {
        width: 100px;
        color: #f47d30 !important;
        font-family: Poppins !important;
        text-align: center !important;
        font-size: 16px !important;
    }

    .rdtPicker .dow {
        width: 1.7rem;
        border-bottom: none;
        cursor: default;
        color: #000;
        font-size: 13px;
    }


    .rdtCounter .rdtBtn:hover {
        background: #fff !important;
    }


    .rdtPicker thead tr:first-of-type th:hover {
        background: #fff !important;
    }

    .rdtPicker .rdtTimeToggle:hover {
        background: #fff !important;
        cursor: pointer;
    }

    th .rdtSwitch {
        color: #f47d30 !important;
        font-family: Poppins !important;
        text-align: center !important;
        font-size: 16px !important;
    }

    .rdtDay {
        color: #000;
        font-size: 14px;
        font-weight: normal;
        width: 1.7rem;
        line-height: 1.7rem;
        text-align: center;
        margin: .166rem;
    }

    .rdt:before {
        position: absolute;
        /*  background: url('calendar.png');*/
        font-family: 'Font Awesome 5 Free';
        content: "\f133" !important;
        font-weight: 500;
        font-style: normal;
        font-size: 25px;
        margin-left: 7px;
        margin-top: -5px;
        color: #919195;
    }

    .rdt:after {
        /*  background: url('icons/chevron - down/down.png');*/
        font-family: 'Font Awesome 5 Free';
        content: "\f0d7" !important;
        font-style: normal;
        font-size: 25px;
        color: #919195;
        float: right;
        margin-top: -40px;
        margin-right: 10px;
    }

    .rdt-UTC {
        text-align: center;
    }

    div[class="partsForm"] select[class="form-control customSelect selectUser"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('icons/chevron - down/down.png') 99% / 5% no-repeat;
    }

        div[class="partsForm"] select[class="form-control customSelect selectUser"]:disabled {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: url('icons/chevron - down/down.png') 99% / 5% no-repeat;
            background-color: #e9ecef;
            opacity: 1;
        }

    .worksheet-step {
        display: block;
        font-size: 16px !important;
        margin: 0px;
        padding: 0px;
    }

    .traxfile-step {
        display: block;
        font-size: 16px !important;
        margin: 0px;
        padding: 0px;
    }

    .planningFilterChevrondown {
        height: 1em !important;
    }

    .positionFilter .popover {
        transform: translate3d(151px, 230px, 0px) !important;
    }

    .bottom-0 {
        bottom: 0px !important;
    }

        .bottom-0 > .popover {
            margin-bottom: 0px !important;
        }

.bg-card-color {
    background-color: #f2f2f4 !important;
}

.rc-time-picker {
    /* border: 1px solid #000;*/
    margin-left: 10px;
    height: 38px !important;
    width: 100%;
}
.rc-time-picker-input {
    text-align:center;
    font-family: Poppins !important;
    height: 38px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #919195 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 2px solid #eff1f5 !important;
    border-radius: 5px !important;
    padding: 4px 13px !important;
}
    .rc-time-picker-input:focus {
        
        border-color: #f47d30 !important;
       /* box-shadow: 0 0 0 0.2rem rgba(244, 125, 48,0.25) !important;*/
        outline: none;
    }
    .rc-time-picker-input:hover {
        border-color: #f47d30 !important;
        /* box-shadow: 0 0 0 0.2rem rgba(244, 125, 48,0.25) !important;*/
        outline: none;
    }
    .rc-time-picker-input[disabled] {
        background: #e9ecef !important;
        color: #919195 !important;        
    }
.rc-time-picker-clear-icon  {
    display: none !important;
    pointer-events: none !important;
    cursor: auto !important;
} 
.rc-time-picker-panel-input {
    padding-left: 10px;
    font-family: Poppins !important;
    color: #919195 !important;
    font-size: 16px !important;
    text-align:center !important;
}

.rc-time-picker-panel-select {
    font-family: Poppins !important;
    color: #919195 !important;
    font-size: 16px !important;
}
.rc-time-picker-panel-select:last-child {
    position: absolute;
    display: inline;
}
.rc-time-picker-panel-select-option-selected {
    color: #f47d30 !important;
    background-color: #ffd0bc !important;
}
.rc-time-picker-panel-select li:hover {
    background-color: #ffd0bc !important;
}

.manpowerDate > .react-daterange-picker__wrapper {
    justify-content: center !important;
    padding: 4px !important;
    color: #919195 !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    background-color: #fff !important;
    border: 2px solid #eff1f5 !important;
    border-radius: 5px !important;
    height: 48px !important;
    padding-top: 10px;
}

    .manpowerDate > .react-daterange-picker__wrapper:hover {
        border: solid 2px #f47d30 !important;
    }
    .manpowerDate > .react-daterange-picker__wrapper:focus {
        border: solid 2px #f47d30 !important;
    }

.manpowerDate > .react-daterange-picker__wrapper > .react-daterange-picker__inputGroup {
    flex-grow: unset !important;
}

.manpowerDate> .react-daterange-picker__wrapper >.react-daterange-picker__clear-button{
    margin-left: auto !important;
}

.react-daterange-picker__calendar {
    width: 296px !important;
}
.manpower-export-button {
    padding: 12px 40px !important;
    border: solid 2px #f47d30;
}

.manpower-export {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 0px !important;
    padding-top: 20px;
}
.manpower-export-button-disable {
    background-color: #ffffff;
    color: #B4b7bf;
    border: 2px solid #B4b7bf;
    pointer-events: none;
}
.deferral-export-button-disable {
    background-color: #ffffff;
    color: #B4b7bf;
    border: 2px solid #B4b7bf;
    pointer-events: none;
}

.manpowerGrid > div >.ag-root-wrapper > .ag-paging-panel {
    font-size:18px;
    height:40px !important;
    margin-top:-1px !important;
}


#manpowerGrid > .ag-theme-balham button {
    height: 27px !important;
    width: 27px !important;
    background-size: 27px 27px !important;
    cursor:pointer !important;
    opacity:1 !important;
}

#manpowerGrid >.ag-theme-balham button[disabled]{
    opacity:0.38 !important;
}

#manpowerGrid > .react-daterange-picker__inputGroup__input {
    color: #636569 !important;
}
.softwareVerModal{
    max-width:1000px !important;
    font-family:Poppins;
}
.softwareVerModal .modal-content{
    max-height:600px;
    min-height:500px;
    overflow-y:auto;
}
.softwareVersion {
    font-weight: bold;
    font-size: 18px;
    width: 16ch;
    letter-spacing: 1px;
}
.versionLineheight{
    line-height:24px;
}
.version {
    color: #636569;
    font-size: 16px;
    letter-spacing: 0.5px;
}
.bugFix{
    font-size:16px;
    letter-spacing:0.5px;
    font-weight:600;
}

.viewButtonMx {
   color: #212529;
   margin-right:5px;
   margin-left:10px;
}

    .viewButtonMx:hover {
        color: #212529;
    }

.employeeNameWidth {
    max-width: 13ch;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.round-empty-button-disable {
    background-color: #ffffff;
    color: #B4b7bf;
    border: 2px solid #B4b7bf;
    pointer-events: none;
}